import React, { useCallback, useEffect, useState } from "react";

import {
    Button,
    Card,
    Col,
    Container,
    Row,
    OverlayTrigger, 
    Tooltip,
    Table,
} from "react-bootstrap";
import {
    ArrowLeft,
    DollarSign,
    Edit3,
    FilePlus,
    User,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import api from "../../../../../service/api";
import InvoiceCreditNotes from "./InvoiceCreditNotes";
import StudentChangeHistory from "./StudentChangeHistory";
import { useSelector } from "react-redux";
// import Modal from "react-overlays/Modal";
import "../../../Sales/modal-style.css";
import {
    addDefaultOption,
    beforeGSTAmount,
    calculateCommonBasedBeforeGSTSubtotal,
    calculateCommonSubtotal,
    commonBeforeGSTAmount,
    formatNumberWithCommas,
    getAndDeleteLocalStorageValue,
    getLocalStorageValue,
    roundToTwoDecimalDigits,
} from "../../../../../utils/utility";
// import { addDefaultOption } from "../../../utils/utility";
// import { Formik } from "formik";
// import Select from "react-select";
// import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
// import StudentList from "../../CreditNotes/CreditNoteRequest/StudentList";
// import { TableColumns } from "./TableColumns";
// import StudentList from "./StudentList";
import AdditionalInvoiceModal from "./AdditionalInvoiceModal";
import EditCompanyModal from "./EditCompanyModal";
import InvoiceHistoryLog from "./InvoiceHistoryLog";

const InvoiceDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState();
    const [loading, setLoading] = useState(false);
    const [subTotal, setSubtotal] = useState();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const option = urlParams.get("option");
    const page = urlParams.get("page");
    const type = urlParams.get("type");
    const [error, setError] = useState();
    const [invoiceStudent, setInvoiceStudent] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [studentTextBox, setStudentTextbox] = useState();
    const [isSalesInvoice, setIsSalesInvoice] = useState(false);

    const [salesPerson, setSalesPerson] = useState();
    const [rerender, setRerender] = useState(false);
    /** Notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    // React state to control Modal visibility
    const [showModal, setShowModal] = useState(false);
    const [showModalEditCompany, setShowModalEditCompany] = useState(false);

    // Backdrop JSX code
    const renderBackdrop = (props) => <div className="backdrop" {...props} />;

    /** permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;

    /** get the invoice */
    const getInvoice = useCallback(async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`tms/invoices/${id}`);
            const response_students = await api.get(
                `tms/invoice-students/${id}`
            );
            setInvoice(response.data);
            setIsSalesInvoice(response?.data?.invoice_type?.toUpperCase() === "SALES")
            console.log(response.data);
            getSubTotal(response?.data);
            setInvoiceStudent(response_students.data);
            setLoading(false);
            getCompanySales(response.data.company_id);
        } catch (error) {}
    }, []);

    const getSubTotal = (data) => {
        let subTotals = data?.invoice_courses?.reduce(
            (accum, item) =>
                accum +
                ((item.sub_total_after_gst - data.discount) /
                    item.total_student /
                    1.08) *
                    item.total_student,
            0
        );
        setSubtotal(subTotals);
    };

    /**  use efffect to recall calculate */
    useEffect(() => {
        getInvoice(id);
    }, [id, getInvoice, rerender]);

    /**  use efffect to call the get quotation */
    useEffect(() => {
        recalculate();
    }, [selectedUser, handleFormInputChange]);

    const getCompanySales = async (id) => {
        const response = await api.get(`tms/companies-sales/${id}`, {});
        let groupedOptions = [
            {
                label: "Default",
                options: [],
            },
            {
                label: "All Salesperson",
                options: [],
            },
            {
                label: "Current User",
                options: [],
            },
        ];

        if (response.data.hasOwnProperty("default")) {
            for (const [key, item] of Object.entries(response.data?.default)) {
                groupedOptions[0].options.push({
                    label: item.label,
                    value: item.value,
                });
            }
        }
        if (response.data.hasOwnProperty("all")) {
            for (const [key, item] of Object.entries(response.data?.all)) {
                groupedOptions[1].options.push({
                    label: item.label,
                    value: item.value,
                });
            }
        }
        if (response.data.hasOwnProperty("current_user")) {
            for (const [key, item] of Object.entries(
                response.data?.current_user
            )) {
                groupedOptions[0].options.push({
                    label: item.label,
                    value: item.value,
                });
            }
        }

        setSalesPerson(groupedOptions);
    };

    const recalculate = () => {
        let data = initValues;
        let default_gst = "0.0" + initValues.gst;
        let total_stud = selectedUser?.length;
        data["total_student"] = total_stud;
        data["students"] = selectedUser?.map((data, index) => {
            return (data[index] = data.original);
        });
        if (
            total_stud > 0 &&
            total_stud !== "" &&
            data["course_fee"] > 0 &&
            data["course_fee"] !== ""
        ) {
            let sub_total = data["course_fee"] * total_stud;
            let gst_amount = Math.round((parseFloat((data.original_price * data.total_student) - (data.course_fee * data.total_student))) * 100) / 100;
            if (data.gst_type) {
                gst_amount = 0;
            }
            let total_amount = parseFloat(sub_total + gst_amount).toFixed(3);
            total_amount = Math.round((total_amount) * 100) / 100;
            data["total_amount"] = parseFloat(total_amount).toFixed(2);
            // data["gst_amount"] = Math.round((gst_amount.toFixed(3)) * 100) / 100
            data["gst_amount"] = gst_amount;
            data["sub_total"] = parseFloat(sub_total).toFixed(2);
        } else {
            data["total_amount"] = 0;
            data["gst_amount"] = 0;
            data["sub_total"] = "";
        }
        setInitialValues({
            ...initValues,
            ...data,
        });
    };

    const no_gst = (event) => {
        let data = initValues;
        let total_stud = selectedUser?.length;
        let calculative_gst = "1.0" + data.default_gst;
        let fee = data.original_price
        let course_fee = data["original_price"];
        if (data.type !== "BR2") {
            course_fee = ((fee/1)/calculative_gst)*1;
        }
        if (event.target.checked) {
            let new_total = course_fee * total_stud;
            data["gst_amount"] = 0;
            data["no_gst"] = true;
            data["gst"] = 0;
            data["course_fee"] = parseFloat(course_fee).toFixed(2);
            data["total_amount"] = new_total.toFixed(2);
            setInitialValues({
                ...initValues,
                ...data,
            });

        } else {
            fee = ((fee/1)/calculative_gst)*1;
            data["course_fee"] = Math.round((fee) * 100) / 100;
            data["gst"] = initValues.default_gst;
            data["no_gst"] = false;
            setInitialValues({
                ...initValues,
                ...data,
            });
            // recalculate();
        }
        recalculate();
    };

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATO";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATTC";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const { atoCourses, attcCourses } = { ...configValues };
        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };

    var handleClose = () => setShowModal(false);
    var handleSuccess = () => {
        create(initValues);
    };

    var handleCloseEditCompany = () => setShowModalEditCompany(false);
    var handleSuccessEditCompany = () => {
        updateInvoice();
    };

    const updateInvoice = async () => {
        let data = EditCompany;

        try {
            const response = await api.post(
                `tms/invoice/company-edit/${data.invoice_id}`,
                data
            );
            if (response.status === "ok") {
                setShowModalEditCompany(false);
                setNotif({
                    notifMsg: "Something went wrong with the server",
                    open: true,
                    severity: "danger",
                });
            }
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const [initValues, setInitialValues] = useState({
        type: "",
        department: "attc",
        trade_category: "",
        type_of_trade: "",
        application_option: "",
        course_id: "",
        course_fee: "",
        total_amount: "",
        sub_total: "",
        total_student: "",
        gst: configValues?.gst ? configValues?.gst : "8",
        default_gst: configValues?.gst ? configValues?.gst : "8",
        gst_amount: "",
        no_session: "",
        original_price: "",
        additional_training: "",
        students: [],
        no_gst: false,
        item_type: "course",
        gst_type: false,
    });

    const [EditCompany, setEditCompany] = useState({
        company_addr: "",
        sales_user_id: "",
        sync_addr: 0,
        invoice_id: "",
        company_id: "",
    });
    // const [editEnrolment, setEditEnrolment] = useState();
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        paymentMode: [],
        additionalTraining: [],
        atoCourses: [],
        attcCourses: [],
        nationality: [],
        companies: [],
        registrationOption: [],
        workPermits: [],
        additional: [],
    });

    const [addInvoiceItem, setAddInvoiceItem] = useState();
    /** use effect */
    useEffect(() => {
        const getConfig = async () => {
            try {
                const configResponse = await api.get(
                    `tms/enrolments/config`,
                    {}
                );

                const addInvoiceResponse = await api.get(
                    `tms/invoice/additional-invoice`,
                    {}
                );

                const addInvoiceItemResponse = await api.get(
                    `tms/invoice/get-add-invoice-item`,
                    {}
                );

                const courseResponse = await api.get(`tms/courses?all=1`, {});
                setCourseData(courseResponse?.data);
                setConfigValues({
                    ...configValues,
                    ...configResponse.data,
                    ...addInvoiceResponse.data,
                });
                setAddInvoiceItem(addInvoiceItemResponse.data);
                setLoading(false);
                // console.log(addInvoiceItem);
                // setInitialValues('total_student',invoice.invoice_courses[0]['allowed_students'] )
            } catch (error) {}
        };

        getConfig();
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps

    const handleFormSelectChange = (index, value) => {
        let data = initValues;
        data[index] = value.value;
        setInitialValues({
            ...initValues,
            ...data,
        });
    };
    const handleFormSelectTypeChange = (index, value) => {
        let data = initValues;
        data[index] = value.value;
        if(index === 'type') {
            data[index] = value.label;
        }
        data['gst_type'] = value.gst_type;
        data["no_gst"] = false;
        if (data.gst_type) {
            data["no_gst"] = true;
        }
        setInitialValues({
            ...initValues,
            ...data,
        });
    };

    const student = (index, value) => {
        let data = initValues;
        data[index] = value;

        setInitialValues({
            ...initValues,
            ...data,
        });
    };

    const handleFormInputChange = (index, value) => {
        let data = initValues;
        let default_gst = "0.0" + initValues.gst;
        let total_stud = selectedUser?.length ?? 0;
        data[index] = value;
        data["total_student"] = total_stud;
        if (
            total_stud > 0 &&
            total_stud !== "" &&

            data["course_fee"] > 0 &&
            data["course_fee"] !== ""
        ) {
            // let sub_total = data['course_fee'] * data['total_student'];

            let sub_total = data["course_fee"] * total_stud;
            let gst_amount = sub_total * default_gst;
            let total_amount = sub_total;
            data["total_amount"] = parseFloat(total_amount).toFixed(2);
            // data["gst_amount"] = parseFloat(gst_amount).toFixed(2);
            data["gst_amount"] = Math.round((gst_amount) * 100) / 100
            data["sub_total"] = parseFloat(sub_total).toFixed(2);
            data["total_student"] = total_stud;
        //     data["original_price"] = (original_price)*total_stud.toFixed(2);
        }
        setInitialValues({
            ...initValues,
            ...data,
        });
    };

    const handleFormInputTypeChange = (value) => {
        let data = initValues;
        let fee = addInvoiceItem[value].fee;
        let calculative_gst = "1.0" + data.gst;
        data["original_price"] = parseFloat(fee).toFixed(2);
        fee = ((fee/1)/calculative_gst)*1;
        let total_stud = selectedUser?.length ?? 0;
        data["course_fee"] = parseFloat(fee).toFixed(2);
        if (data.gst_type) {
            data["course_fee"] = data.original_price;
        }
        data["sub_total"] = parseFloat(fee * total_stud).toFixed(2);

        setInitialValues({
            ...initValues,
            ...data,
        });
        recalculate();
    };

    const handleFormInputStudTableChange = (name, event, index) => {
        let data = initValues;
        
        if (data.students.length <= 0) {
            setNotif({
                notifMsg: "Please select student/s",
                open: true,
                severity: "danger",
            });return;
        }
        
        data['students'][index][name] = event.target.value;
        setInitialValues({
            ...initValues,
            ...data,
        });
        recalculate();
    };

    /** functions */
    const create = async (values) => {
        // console.log(values);return;
        if (values.sub_total <= 0 && values.sub_total === "") {
            setNotif({
                notifMsg: "Please check the data",
                open: true,
                severity: "danger",
            });
        }
        if (values.students.length <= 0) {
            setNotif({
                notifMsg: "Please select student/s",
                open: true,
                severity: "danger",
            });
        }
        if (values.total_amount <= 0 && values.total_amount === "") {
            setNotif({
                notifMsg: "Please check the data",
                open: true,
                severity: "danger",
            });
        }
        try {
            // return api.post(`tms/invoice/additiona-invoice/${}`, values);
            const response = await api.post(
                `tms/invoice/additional-invoice/${id}`,
                values
            );
            if (response.success) {
                setShowModal(false);
                setNotif({
                    notifMsg: "Additional Invoice is now created",
                    open: true,
                    severity: "success",
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const handleFormInputChangeCompanyAddr = (event) => {
        setEditCompany({
            ...EditCompany,
            company_addr: event.target.value,
        });
    };

    const handleCheckBoxCompAdd = (event) => {
        // console.log(event);
        let data = EditCompany;
        data["sync_addr"] = 0;

        if (event.target.checked) {
            data["sync_addr"] = 1;
        }
        setEditCompany(data);
    };

    const handleChangeGSTAmount = (value) => {
        let data = initValues;

        data['gst_amount'] = value;
        let new_total = (parseFloat(value) + parseFloat(data.sub_total)).toFixed(2)
        data['total_amount'] = new_total;
        setInitialValues({
            ...initValues,
            ...data,
        });
    };
    
    const totalTaxPercentage = invoice?.no_gst
        ? "0"
        : parseFloat(invoice?.gst).toFixed(0);


    const backButtonOnClickHandler = () => {
        if (getLocalStorageValue('invoice-back-button-redirect')) {
            navigate(
                getAndDeleteLocalStorageValue('invoice-back-button-redirect', '/tms/invoices')
            )
        }else{
            type === "report"
                ? navigate(`/tms/invoice-report/`)
                : navigate(`/tms/invoices/`)
        }
    }

    return (
        <>
            
            <AdditionalInvoiceModal 
                loading={loading}
                invoiceStudent={invoiceStudent}
                handleClose={handleClose}
                showModal={showModal}
                renderBackdrop={renderBackdrop}
                invoice={invoice}
                configValues={configValues}
                handleFormSelectChange={handleFormSelectChange}
                handleFormInputTypeChange={handleFormInputTypeChange}
                initValues={initValues}
                setSelectedUser={setSelectedUser}
                handleFormInputStudTableChange={handleFormInputStudTableChange}
                no_gst={no_gst}
                handleSuccess={handleSuccess}
                handleChangeGSTAmount={handleChangeGSTAmount}
                handleFormSelectTypeChange={handleFormSelectTypeChange}
            />

            <Helmet title="Invoice > Details" />
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <h1 className="h3 mb-3">
                                    {invoice?.sub_invoice
                                        ? "Sub Invoice details ( Main invoice: " +
                                          invoice?.main_invoice?.number +
                                          ")"
                                        : "Main Invoice details"}
                                </h1>
                            </Col>
                            <Col md={6} className="text-end">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Return</Tooltip>}
                                >
                                <Button
                                    className="ms-2 "
                                    onClick={backButtonOnClickHandler}
                                >
                                    <ArrowLeft className="feather" />
                                </Button>
                                </OverlayTrigger>
                                {permission?.includes("PAYMENT-CREATE") && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Payment</Tooltip>}
                                    >
                                        <Button
                                            variant="success"
                                            className=" ms-2  "
                                            onClick={() => {
                                                navigate(
                                                    `/tms/invoices/payments/${invoice?.id}`
                                                );
                                            }}
                                        >
                                            <DollarSign size={15}></DollarSign>
                                        </Button>
                                    </OverlayTrigger>
                                )}

                                {permission?.includes(
                                    "INVOICE-MANAGE-STUDENT"
                                ) && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Manage Students</Tooltip>}
                                    >
                                        <Button
                                            variant="info"
                                            className=" ms-2  "
                                            onClick={() => {
                                                navigate(
                                                    `/tms/invoices/manage-students/${invoice?.id}`
                                                );
                                            }}
                                        >
                                            <User size={15}></User>
                                        </Button>
                                    </OverlayTrigger>
                                )}
                                {/* {invoice && (
                                    <>
                                        {permission?.includes(
                                            "INVOICE-MANAGE-STUDENT"
                                        ) &&
                                            invoice.sync_enrolment !== true &&
                                            invoice.sync_enrolment !== null &&
                                            invoice.sub_invoice !== true && (
                                                <Button
                                                    variant="info"
                                                    className=" ms-2  "
                                                    // onClick={() =>
                                                    //     setShowModal(true)
                                                    // }
                                                    onClick={() => {
                                                        navigate(
                                                            `/tms/additional/${invoice?.id}/add`
                                                        );
                                                    }}
                                                >
                                                    <FilePlus
                                                        size={15}
                                                    ></FilePlus>
                                                </Button>
                                            )}
                                    </>
                                )} */}
                                {invoice && (
                                    <>
                                        {permission?.includes(
                                            "INVOICE-MANAGE-STUDENT"
                                        ) && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Edit Invoice</Tooltip>}
                                            >
                                                <Button
                                                    variant="info"
                                                    className=" ms-2  "
                                                    onClick={() => {
                                                        setShowModalEditCompany(
                                                            true
                                                        );
                                                        setEditCompany({
                                                            ...EditCompany,
                                                            company_addr:
                                                                invoice.company_addr,
                                                            sales_user_id:
                                                                invoice.sales_user_id,
                                                            invoice_id: invoice.id,
                                                            company_id:
                                                                invoice.company_id,
                                                        });
                                                    }}
                                                >
                                                    <Edit3 size={15}></Edit3>
                                                </Button>
                                            </OverlayTrigger>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        {loading && <div>Loading...</div>}
                        {!loading && invoice && (
                            <>
                                <Row>
                                    <Col md={6}>
                                        <Row >
                                            <Row className="p-0 xs-pb-5">
                                                <Col>
                                                <h3 className="mb-0 pb-0">
                                                {invoice?.company?.name}
                                                </h3>
                                                {/* <h5 className="mb-3 ">
                                                    {invoice?.company?.uen}
                                                </h5> */}
                                                {invoice?.company_addr}
                                                </Col>
                                            </Row>
                                            <Container fluid>
                                            <Row className="pt-5">
                                                <Row  className="font-weight-bold">
                                                    <Col md={3}>
                                                        <h5>Attention: </h5>
                                                    </Col>
                                                    <Col md={9}>
                                                        <h5>{invoice?.company_contact_person?.toUpperCase()}</h5>
                                                    </Col>
                                                </Row>

                                                <Row  className="font-weight-bold">
                                                    <Col md={3}>
                                                        <h5>Contact number: </h5>
                                                    </Col>
                                                    <Col md={9}>
                                                        <h5>{invoice?.company_contact_no}</h5>
                                                    </Col>
                                                </Row>
                                            </Row>

                                            </Container>
                                            
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end">
                                            {/* {console.log(invoice)} */}
                                            <table className="table">
                                                <tbody className="font-weight-bold">
                                                    <tr>
                                                        <td>Invoice no</td>
                                                        <td>
                                                            {invoice?.number}
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>Status</td>
                                                        <td style={{ textTransform: 'capitalize' }}>
                                                            <Badge>
                                                                {
                                                                    invoice?.status
                                                                }
                                                            </Badge>
                                                        </td>
                                                    </tr> */}
                                                    <tr>
                                                        <td>Date</td>
                                                        <td>{invoice?.date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Terms</td>
                                                        <td>C.O.D</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Salesperson</td>
                                                        <td>
                                                            {
                                                                invoice
                                                                    ?.sales_user
                                                                    ?.name
                                                            }
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                                
                                {invoice?.invoice_courses?.length > 0 && (
                                    <>
                                        <Table className="table" responsive>
                                            <thead className="font-weight-bold">
                                                <tr>
                                                    <td width="5%">S/n</td>
                                                    <td width="35%">
                                                       Description
                                                    </td>
                                                    {!isSalesInvoice && (
                                                        <td width="5%">No of days</td>
                                                    )}
                                                    <td width="5%">{ invoice?.invoice_type?.toUpperCase() === "SALES" ? 'Student' : 'Quantity' }</td>

                                                    <td width="5%" className="text-nowrap">Unit price</td>
                                                    <td width="5%">Amount</td>
                                                </tr>
                                            </thead>
                                            {/** Quotation Items */}
                                            <tbody>
                                                {invoice?.invoice_courses
                                                    ?.length > 0 &&
                                                    invoice?.invoice_courses?.map(
                                                        (item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td width="5%">
                                                                        {index +
                                                                            1}
                                                                    </td>
                                                                    <td>
                                                                    {
                                                                            item?.course?.name && (
                                                                                <>
                                                                                {item?.course?.name.toUpperCase()}
                                                                                    {/* <tr>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.course?.name }
                                                                                        </td>
                                                                                    </tr> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            item?.course_fee?.course_code && !(item?.addtnl_invoice_item_code) && (
                                                                                <>
                                                                                    <tr>
                                                                                    <b>Item code :</b>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.course_fee?.course_code }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            item?.addtnl_invoice_item_code && (
                                                                                <>
                                                                                    <tr>
                                                                                    <b>Additional Item :</b>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.adhoc_additional_item_code }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }

                                                                        {
                                                                            item?.trade_category && (
                                                                                <>
                                                                                    <tr>
                                                                                    <b>Trade category :</b>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.trade_category }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            item.type_of_trade && (
                                                                                <>
                                                                                    <tr>
                                                                                    <b>Type of trade :</b>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.type_of_trade }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            item?.application_option && (
                                                                                <>
                                                                                    <tr>
                                                                                    <b>Application option :</b>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.application_option }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }

                                                                        {
                                                                            item?.discount_item_name && invoice?.invoice_type?.toUpperCase() === 'AD-HOC' && (
                                                                                <>
                                                                                    <tr>
                                                                                        {item?.item_type !== 'discount' && (
                                                                                            <b>Other :</b>
                                                                                        )}
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.discount_item_name }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }     

                                                                         {
                                                                            item?.discount_item_name && invoice?.invoice_type?.toUpperCase() === 'SALES' && (
                                                                                <>
                                                                                    <tr>
                                                                                    <b>Discount :</b>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            {item?.discount_item_name }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }       
                                                                       
                                                                        {/* Additional Invoice / Sales Invoice*/}
                                                                        {
                                                                            item?.invoice_course_students?.length > 0 && item?.invoice_course_students?.enrolment && invoice?.invoice_type?.toUpperCase() === 'ADDITIONAL' && (
                                                                                <>
                                                                                    <tr>
                                                                                        <b>Students :</b>
                                                                                        <td width="10%"></td>
                                                                                        <td>
                                                                                        { item?.invoice_course_students?.map(
                                                                                            (data, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {data.enrolment.name+ ' ' + data.enrolment.fin.slice(0, -4).replace(/./g, '*') + data.enrolment.fin.slice(-4)}<br></br>
                                                                                                    </>
                                                                                                )
                                                                                                    
                                                                                            }
                                                                                        )}
                                                                                        </td>
                                                                                    </tr>
                                                                                <br></br>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            item?.invoice_course_students?.length > 0 && !item?.invoice_course_students?.enrolment && invoice?.invoice_type?.toUpperCase() === 'ADDITIONAL' && (
                                                                                <>
                                                                                    <tr>
                                                                                        <b>Students :</b>
                                                                                        <td width="10%"></td>
                                                                                        <td>
                                                                                        { item?.invoice_course_students.map(
                                                                                            (data, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {(data.student_name ?? data.student?.name ?? '')+ ' ' + 
                                                                                                        (data.fin?.slice(0, -4).replace(/./g, '*') ?? data.student?.fin.slice(0, -4).replace(/./g, '*') ?? '') + 
                                                                                                        (data.fin?.slice(-4) ?? data.student?.fin.slice(-4) ?? '')}
                                                                                                        <br></br>
                                                                                                    </>
                                                                                                )
                                                                                                    
                                                                                            }
                                                                                        )}
                                                                                        </td>
                                                                                    </tr>
                                                                                <br></br>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {/*Sales Invoice*/}
                                                                        {
                                                                            item?.invoice_course_students?.length > 0 && item?.invoice_course_students?.enrolment && invoice?.invoice_type?.toUpperCase() === 'SALES' && (
                                                                                <>
                                                                                    <tr>
                                                                                        <b>Students :</b>
                                                                                        <td width="10%"></td>
                                                                                        <td>
                                                                                        { item?.invoice_course_students?.map(
                                                                                            (data, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {data.enrolment.name+ ' ' + data.enrolment.fin.slice(0, -4).replace(/./g, '*') + data.enrolment.fin.slice(-4)}<br></br>
                                                                                                    </>
                                                                                                )
                                                                                                    
                                                                                            }
                                                                                        )}
                                                                                        </td>
                                                                                    </tr>
                                                                                <br></br>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            item?.invoice_course_students?.length > 0 && !item?.invoice_course_students?.enrolment && invoice?.invoice_type?.toUpperCase() === 'SALES' && (
                                                                                <>
                                                                                    <tr>
                                                                                        <b>Students :</b>
                                                                                        <td width="10%"></td>
                                                                                        <td>
                                                                                        { item?.invoice_course_students?.map(
                                                                                            (data, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {(data.student_name ?? data.student?.name ?? '')+ ' ' + 
                                                                                                        (data.fin?.slice(0, -4).replace(/./g, '*') ?? data.student?.fin.slice(0, -4).replace(/./g, '*') ?? '') + 
                                                                                                        (data.fin?.slice(-4) ?? data.student?.fin.slice(-4) ?? '')}
                                                                                                        <br></br>
                                                                                                    </>
                                                                                                )
                                                                                                    
                                                                                            }
                                                                                        )}
                                                                                        </td>
                                                                                    </tr>
                                                                                <br></br>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {/* ad-hoc Invoice */}
                                                                        
                                                                        {
                                                                            item?.invoice_course_students?.length > 0 && invoice?.invoice_type?.toUpperCase() === 'AD-HOC' && (
                                                                                <>
                                                                                <tr>
                                                                                    <b>Students :</b>
                                                                                    <td width="10%"></td>
                                                                                    <td>
                                                                                    { item?.invoice_course_students?.map(
                                                                                        (data, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    {(data.student_name ?? data.student?.name ?? '')+ ' ' + 
                                                                                                    (data.fin?.slice(0, -4).replace(/./g, '*') ?? data.student?.fin.slice(0, -4).replace(/./g, '*') ?? '') + 
                                                                                                    (data.fin?.slice(-4) ?? data.student?.fin.slice(-4) ?? '')}
                                                                                                    <br></br>
                                                                                                </>
                                                                                            )
                                                                                                
                                                                                        }
                                                                                    )}
                                                                                    </td>
                                                                                </tr>
                                                                            <br></br>
                                                                            </>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    {!isSalesInvoice && (
                                                                        <td>
                                                                            <tr>
                                                                                <br/>
                                                                            </tr>
                                                                            {
                                                                            item?.invoice_course_students?.length > 0 && (
                                                                                <>
                                                                                <tr>
                                                                                    <td width="10%"><br/><br/></td>
                                                                                    <td>
                                                                                    { item?.invoice_course_students?.map(
                                                                                        (data, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    {data?.no_session && (parseInt(data?.no_session) > 0) ? data?.no_session + " days" : ""}<br/>
                                                                                                </>
                                                                                            )
                                                                                                
                                                                                        }
                                                                                    )}
                                                                                    </td>
                                                                                </tr>
                                                                            <br></br>
                                                                            </>
                                                                            )
                                                                        }
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        {
                                                                            // invoice?.invoice_type?.toUpperCase() === "SALES" 
                                                                            //     ? item.total_student
                                                                            //     : item.total_student * (item?.invoice_course_students.length > 0 ? (item?.invoice_course_students[0]['no_session'] !== null && 
                                                                            //                                                                         item?.invoice_course_students[0]['no_session'] !== 0 && item?.invoice_course_students[0]['no_session'] !== "0" ? 
                                                                            //                                                                         item?.invoice_course_students[0]['no_session'] : 
                                                                            //                                                                         1) :
                                                                            //                                                                         1)
                                                                            item.total_student
                                                                        }
                                                                    </td>

                                                                    <td className="currency text-nowrap">
                                                                        {invoice?.no_gst &&
                                                                            item.price
                                                                        }
                                                                        {invoice?.gst_amount > 0 && 
                                                                            <>
                                                                                {
                                                                                    formatNumberWithCommas(
                                                                                        roundToTwoDecimalDigits(
                                                                                        beforeGSTAmount(
                                                                                            item.price,
                                                                                            invoice?.gst,
                                                                                            invoice?.no_gst
                                                                                        )
                                                                                    ))
                                                                                }
                                                                                <br />
                                                                                {/* <small>
                                                                                    After
                                                                                    
                                                                                    GST $  
                                                                                    {formatNumberWithCommas(item.price)}
                                                                                </small> */}
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td className="currency text-nowrap">
                                                                        <>
                                                                            {
                                                                                formatNumberWithCommas(
                                                                                    roundToTwoDecimalDigits(
                                                                                        calculateCommonBasedBeforeGSTSubtotal(
                                                                                            item?.price,
                                                                                            invoice?.gst,
                                                                                            item?.total_student,
                                                                                            invoice?.no_gst
                                                                                        )
                                                                                    )
                                                                                )
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}

                                                <tr >
                                                    <td
                                                        colSpan={isSalesInvoice ? "4" : "5"}
                                                        className="text-end fs-4 border-0 font-weight-bold"
                                                    >
                                                        Invoice subtotal
                                                    </td>
                                                    <td className="text-end currency fs-4 border-0 text-nowrap">
                                                        {formatNumberWithCommas(
                                                            // invoice?.subtotal //Commented shows NaN
                                                            invoice?.total_amount - invoice?.gst_amount
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={isSalesInvoice ? "4" : "5"}
                                                        className="text-end fs-4 border-0 font-weight-bold"
                                                    >
                                                        Total before tax
                                                    </td>
                                                    <td className="text-end currency fs-4 border-0 text-nowrap">
                                                        {formatNumberWithCommas(
                                                            invoice?.total_amount - invoice?.gst_amount
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={isSalesInvoice ? "4" : "5"}
                                                        className="text-end fs-4 border-0 font-weight-bold"
                                                    >
                                                        Total tax amount GST{" "}
                                                        {totalTaxPercentage}%
                                                    </td>
                                                    <td className="currency fs-4 border-0 text-nowrap">
                                                        {formatNumberWithCommas(
                                                            invoice?.gst_amount
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        colSpan={isSalesInvoice ? "4" : "5"}
                                                        className="text-end fs-4 border-0 font-weight-bold"
                                                    >
                                                        Total amount
                                                    </td>
                                                    <td className="text-end currency fs-4 border-0 font-weight-bold text-nowrap">
                                                        {formatNumberWithCommas(
                                                            invoice?.total_amount
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                                )}

                                {invoice?.credit_notes?.length > 0 && (
                                    <>
                                        <hr
                                            size={5}
                                            className="text-secondary"
                                        />
                                        <h5>Credit notes</h5>
                                        <InvoiceCreditNotes
                                            creditNotes={invoice?.credit_notes}
                                        />
                                    </>
                                )}

                                {invoice?.change_student_history?.length >
                                    0 && (
                                    <>
                                        <hr
                                            size={5}
                                            className="text-secondary"
                                        />
                                        <h5>Student change history</h5>
                                        <StudentChangeHistory
                                            histories={
                                                invoice?.change_student_history
                                            }
                                        />
                                    </>
                                )}

                                {invoice?.invoice_history_logs && (
                                    <InvoiceHistoryLog
                                        histories={invoice?.invoice_history_logs ?? []}
                                    />
                                )}
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />

            <EditCompanyModal 
                showModalEditCompany={showModalEditCompany}
                handleCloseEditCompany={handleCloseEditCompany}
                renderBackdrop={renderBackdrop}
                loading={loading}
                invoice={invoice}
                salesPerson={salesPerson}
                setEditCompany={setEditCompany}
                EditCompany={EditCompany}
                handleCheckBoxCompAdd={handleCheckBoxCompAdd}
                handleFormInputChangeCompanyAddr={handleFormInputChangeCompanyAddr}
                handleSuccessEditCompany={handleSuccessEditCompany}
                no_gst={initValues.no_gst}
            />
        </>
    );
};

export default InvoiceDetails;

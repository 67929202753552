import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Delete, DollarSign, Download, DownloadCloud, Edit, Edit3, Eye, FilePlus, Repeat, Trash, User, UserPlus } from "react-feather";
import download from "downloadjs";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import api from "../../../../../service/api";

import moment from "moment";
import Invoice from "./Invoice";
import Loader from "../../../../../components/Loader";
const ListButton = ({ id, mainInvoiceId, currentPage, invoice_number, type, isExpired, isDownloadable, isSync_enrolment, status_key, setNotif, rawData}) => {
    const navigate = useNavigate();
    const [isDownloading, setIsDownloading] = useState(false)
    const [isRegenerating, setIsRegenerating] = useState(false)
    /** download */
    const downloadPDF = async (id, invoice_number) => {
        const response = await api.getpdf("tms/generate-invoice-pdf/" + id);
        download(response, invoice_number, "application/pdf");
    };

    const downloadApplicationFormPDF = async (id, invoice_number) => {
        try{
            setIsDownloading(true)
            const response = await api.get("tms/get-application-form-pdf/" + id);
                if(response?.status === 'ok'){
                    //open download link
                    const link = document.createElement("a");
                    link.href = response.data;
                    link.download = `${invoice_number}.zip`;
                    link.click();
                }else{
                    setNotif({
                        notifMsg: "File not yet generated. Please regenerate selected invoice.",
                        open: true,
                        severity: "danger",
                    });
                } 
            setIsDownloading(false)
        }catch(error){
            setNotif({
                notifMsg: error.message + '. Please reload your page.',
                open: true,
                severity: "danger",
            });
        }
    };

    const regenerateApplicationForm = async (id, invoice_number) => {
        try{
            setIsRegenerating(true)
            const response = await api.get("tms/regenerate-application-form-pdf/" + id);
            if(response?.success){
                setNotif({
                    notifMsg: "Regenerating file. Please wait for an email notification.",
                    open: true,
                    severity: "success",
                });
            }else{
                setNotif({
                    notifMsg: "System error. Please notify IT Admin.",
                    open: true,
                    severity: "danger",
                });
            }
            setIsRegenerating(false)
        }catch(error){
            setNotif({
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
        
    };

    /** void */
    const void_invoice = async (id, invoice_number) => {
        const response = await api.post("tms/invoice/void-invoices/" + id);

        if (response.status === 'ok') {
            setNotif({
                notifMsg: "Invoice successfully void",
                open: true,
                severity: "success",
            });
        }
    };

    
    /** permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;

    return (
        <>
            {(permission?.includes("ADHOC-ADDITIONAL-EDIT") &&
                status_key?.toLowerCase() === "pending" && rawData?.invoice_type?.toLowerCase() === "ad-hoc") && (
                <>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <a
                                href={`/tms/ad-hoc/${id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <Edit 
                                        size="20"
                                        color="#6c757d"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                       
                </>
            )}
            {/* Removed the cutoff date to reflect and changed to && because previously
            this was using || meaning the cutoff date was previously not working in f4cb9bc98eb5eaadd34bddb9e0c19467307e3945*/}
            {(permission?.includes("ADHOC-ADDITIONAL-EDIT") &&
                status_key?.toLowerCase() === "pending" && rawData?.invoice_type.toLowerCase() === "additional") && (
                <>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <a
                                href={`/tms/additional/${mainInvoiceId}/${id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <Edit 
                                        size="20"
                                        color="#6c757d"
                                    />
                                </span>
                            </a>
                        </OverlayTrigger>
                       
                </>
            )}
            {permission?.includes("INVOICE-READ") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View invoice</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/invoices/view/${id}?option=view?page=${currentPage}&type=${type}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <Eye size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            {permission?.includes("INVOICE-MANAGE-STUDENT") && rawData?.invoice_type?.toLowerCase() === "sales" && rawData?.status?.toLowerCase() !== 'cancelled' && (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Add additional invoice</Tooltip>}
                    >
                        <a
                            href={`/tms/additional/${id}/add`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <FilePlus 
                                    size="20"
                                    color="#6c757d"
                                />
                            </span>
                        </a>
                    </OverlayTrigger>
                       
                </>
            )}
            {/**Report section hide all links */}
            {type === "operation" && (
                <>
                    {permission?.includes("PAYMENT-CREATE") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Payments</Tooltip>}
                        >
                            <span>
                                <a
                                    href={`/tms/invoices/payments/${id}?page=${currentPage}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-1"
                                >
                                    <span>
                                        <DollarSign size="20" color="#6c757d" />
                                    </span>
                                </a>
                            </span>
                        </OverlayTrigger>
                    )}
                    {permission?.includes("INVOICE-MANAGE-STUDENT") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Manage students</Tooltip>}
                        >
                            <span>
                                <a
                                    href={`/tms/invoices/manage-students/${id}?page=${currentPage}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-1"
                                >
                                    <span>
                                        <User size="20" color="#6c757d" />
                                    </span>
                                </a>
                            </span>
                        </OverlayTrigger>
                    )}
                    {permission?.includes("CREDITNOTE-CREATE") &&
                        // isExpired?.toUpperCase() !== "NO" &&
                        rawData?.status.toLowerCase() !== 'cancelled' &&
                        (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>Invoice credit notes</Tooltip>
                                }
                            >
                                <span>
                                    <a
                                        href={`/tms/invoice-credit-notes-requests/${id}?page=${currentPage}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="me-1"
                                    >
                                        <span>
                                            <Edit3 size="20" color="#6c757d" />
                                        </span>
                                    </a>
                                </span>
                            </OverlayTrigger>
                        )}

                    {/* {permission?.includes("INVOICE-READ") && isDownloadable?.registered_student === isDownloadable?.total_student && ( */}
                    {permission?.includes("INVOICE-READ") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Download</Tooltip>}
                        >
                            <Download
                                style={{ cursor: "pointer" }}
                                size="20"
                                onClick={() => downloadPDF(id, invoice_number)}
                                color="#6c757d"
                            />
                        </OverlayTrigger>
                    )}
                    {permission?.includes("INVOICE-READ") && rawData?.generated_file !== null && !isDownloading && 
                    rawData?.invoice_type?.toLowerCase() === "sales" && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Download Application Form</Tooltip>}
                        >
                            <DownloadCloud
                                style={{ cursor: "pointer" }}
                                size="20"
                                onClick={() => downloadApplicationFormPDF(id, invoice_number)}
                                color="#6c757d"
                            />
                        </OverlayTrigger>
                    )}
                    {isDownloading && (
                        <div class="spinner-border spinner-border-sm text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    )}
                    {permission?.includes("INVOICE-READ") && !isRegenerating && rawData?.invoice_type?.toLowerCase() === "sales" && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Regenerate Application Form</Tooltip>}
                        >
                            <Repeat
                                style={{ cursor: "pointer" }}
                                size="20"
                                onClick={() => regenerateApplicationForm(id, invoice_number)}
                                color="#6c757d"
                            />
                        </OverlayTrigger>
                    )}
                    {isRegenerating && (
                        <div class="spinner-border spinner-border-sm text-primary" role="status">
                            <span class="sr-only fs-10">Loading...</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
export default ListButton;

const tableColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Invoice Number",
        accessor: "invoice_num",
    },
    {
        Header: "Invoice Type",
        accessor: "invoice_type",
    },

    {
        Header: "Company",
        accessor: "company",
    },

    {
        Header: "Amount",
        accessor: "amount",
    },

    {
        Header: "Payment method",
        accessor: "payment_method",
    },

    {
        Header: "Reference number",
        accessor: "reference_num",
    },
    {
        Header: "Supporting Documents",
        accessor: "supporting_documents",
    },
    {
        Header: "Created by",
        accessor: "created_by",
    },

    {
        Header: "Date",
        accessor: (data) => {
            return new Date(data.created_at).toLocaleDateString().split('/').join('-'); 
        }
    },
];

export { tableColumns };

import React, { useState } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { Filter } from "react-feather";
import Select from "react-select";
import { addDefaultOption } from "../../../../utils/utility";
import { minSelectableYear } from "../../../../utils/EnrolmentSummaryConstants";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsyncPaginate } from "react-select-async-paginate";
import { DatePicker } from "antd";
import moment from "moment";
import api from "../../../../service/api";

const Search = ({ setSearchValue, companies, searchValue, search, generatePayments}) => {
    const [hasMore, setHasMore] = useState(true);
    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <Row className="py-3">
            <Col md={2} className="mb-2">
                <Form.Control
                    type="text"
                    name="number"
                    placeholder="Invoice no"
                    size="lg"
                    onChange={(event) => {
                        setSearchValue({
                            ...searchValue,
                            number: event.target.value,
                        });
                    }}
                />
            </Col>

            <Col md={3} className="mb-2">
                <AsyncPaginate
                    className="render-above"
                    isClearable
                    loadOptions={handleLoadMore}
                    hasMore={hasMore}
                    placeholder="Search company"
                    additional={{
                        page: 1,
                    }}
                    onChange={(value) => {
                        setSearchValue({
                            ...searchValue,
                            company: value ? value?.value : "",
                        });
                    }}
                    debounceTimeout={1000}
                />
            </Col>
            <Col md={6} lg={2} className="mb-2">
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Type"
                    isClearable
                    options={addDefaultOption([
                        {
                            value: "invoice_changes",
                            label: "Invoice changes",
                        },
                        {
                            value: "student_changes",
                            label: "Student changes",
                        },
                    ])}
                    name="type"
                    onChange={(event) => {
                        setSearchValue({
                            ...searchValue,
                            type: event?.value,
                        });
                    }}
                />
            </Col>
            <Col md={6} lg={3} className="mb-2">
                <Row className="flex-row ms-md-1">
                    <Col md={5} className="p-md-0">
                        <DatePicker
                            id="from-date-picker"
                            name="from_date"
                            className={`form-control`}
                            placeholder="From date"
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                });
                            }}
                            style={{minHeight: '2.5rem'}}
                        />
                    </Col>
                    <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0">
                        <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                            To
                        </Badge>
                    </Col>
                    <Col md={5} className="p-md-0">
                        <DatePicker
                            id="to-date-picker"
                            name="to_date"
                            className={`form-control`}
                            placeholder="To date"
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                });
                            }}
                            style={{minHeight: '2.5rem'}}
                        />
                    </Col>
                </Row>
            </Col>
            <Col md={2} className="mb-4 mb-2 d-flex justify-content-md-start justify-content-center">
                    <Button className="ms-2" size="lg" variant="info" onClick={() => search()}>
                        <Filter size={15} />
                    </Button>
                    <Button
                        className="ms-2 float-end"
                        variant="info"
                        type="submit"
                        size="lg"
                        onClick={() => generatePayments()}
                    >
                        <FontAwesomeIcon icon={faFileExcel} />{" "}
                    </Button>
            </Col>
        </Row>
    );
};
export default Search;

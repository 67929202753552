// setInitialValues.js

import { commonBeforeGSTAmount } from "../../../../../utils/utility";

export const setInitialValues = (
    quotation,
    id,
    configValues,
    setValue,
    setSelectedCompany,
) => {
    if (!quotation || id === "") {
        return; // No need to proceed if quotation or id is not available
    }

    // Preselect the company by setting its option
    const companyOption = {
        label: quotation?.company?.name,
        value: quotation?.company?.id,
    };
    setSelectedCompany(quotation?.company?.id);

    setValue("company_id", companyOption.value, { shouldValidate: false });
    const locationOption = configValues?.location?.find(
        (option) => option.value === quotation?.location
    );

    if (locationOption) {
        setValue("location", locationOption);
    }
    setValue("sales_user_id", {
        label: quotation?.sales_user?.name,
        value: quotation?.sales_user?.id,
    });

    setValue("address", quotation?.company_addr);

    setValue("no_gst", quotation?.no_gst);
    const companyContactId = quotation.company_contact_id;
    if (companyContactId) {
        setValue("company_contact_person", companyContactId);
    }

    let lastCourseId;
    // Initialize an empty array to store the initial items
    const initialItems = quotation.sales_quotation_course.map((item, index) => {
        const applicationOptionConfig = configValues.tradeApplication?.find(
            (option) => option.value === item.application_option
        );

        const tradeCategoryOptionConfig = configValues.tradeCategory?.find(
            (option) => option.value === item.trade_category
        );

        let courseCodeOptionConfig = configValues.course_code?.find(
            (option) => option.value === item?.course_code?.id
        );


        let parent;
        if(item.item_type === 'course'){
            lastCourseId = item.id;
        }
        
        // Updating this for the Dropdown in edit quotation, not sure if changing the 
        // courseCodeOption might affect different modules
        if(item.item_type === 'additional'){
            courseCodeOptionConfig = {
                label: `${item?.course_code?.course_code ?? null} (${item?.course_code?.item_name ?? null})`,
                value: item?.course_code?.id ?? null
            }
        }

   

        if(['additional', 'discount'].includes(item.item_type)){
             parent = lastCourseId;
        }

        const tradeOptionConfig = configValues.tradeOptions?.find(
            (option) => option.value === item.type_of_trade
        );

        console.log('item id:', item.id);
        return {
            id: item.id,
            uid: item.id,
            total_student: item.total_student,
            price:
                item.item_type !== "discount"
                    ? commonBeforeGSTAmount(
                        parseFloat(item.price),
                        quotation.gst
                    )
                    : parseFloat(item.price),
            original_price: parseFloat(item.price),
            item_type: item.item_type,
            parent: parent ?? null,
            trade_option: tradeOptionConfig || null,
            course_code: courseCodeOptionConfig || null,
            application_option: applicationOptionConfig || null,
            trade_category: tradeCategoryOptionConfig || null,
            course: {
                label: item?.course?.name || "",
                value: item?.course?.id || "",
            },
            student_names: item.student_names,
            discount_item: item.discount_item_name,
        };
    });

    setValue("items", initialItems); // Use setValue to set initial values
};

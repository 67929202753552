import {React, useState} from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import api from "../../../../service/api";
import { Filter } from "react-feather";
import Select from "react-select";
import { addDefaultOption } from "../../../../utils/utility";
import { minSelectableYear } from  "../../../../utils/EnrolmentSummaryConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { DatePicker } from "antd";


const Search = ({ setSearchValue, companies, searchValue, search, generatePayments}) => {
    const [hasMore, setHasMore] = useState(true);
    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };
    return (
        <Row className="py-3">
            <Col md={2} className="mb-2 align-self-center">
                <Form.Control
                    type="text"
                    name="number"
                    placeholder="Invoice no"
                    size="lg"
                    onChange={(event) => {
                        setSearchValue({
                            ...searchValue,
                            number: event.target.value,
                        });
                    }}
                />
            </Col>

            <Col md={3} className="mb-2">
                <Select className={`react-select-container`}
                        classNamePrefix="react-select"
                        placeholder="Invoice Type"

                        options={addDefaultOption(
                            [
                                { label: "AD-HOC", value: "AD-HOC" },
                                { label: "SALES", value: "SALES" },
                                { label: "ADDITIONAL", value: "ADDITIONAL" },
                            ]
                        )}
                        isClearable={true}
                        onChange={(selectedOption) => {
                            setSearchValue({
                                ...searchValue,
                                invoice_type: selectedOption ? selectedOption?.value : "",
                            });
                        }}
                    />
            </Col>

            <Col md={3} className="mb-2 align-self-center">
                <AsyncPaginate
                    isClearable
                    loadOptions={handleLoadMore}
                    hasMore={hasMore}
                    placeholder="Search company"
                    additional={{
                        page: 1,
                    }}
                    onChange={(value) => {
                        setSearchValue({
                            ...searchValue,
                            company: value ? value?.value : "",
                        });
                    }}
                    debounceTimeout={1000}
                />
            </Col>
            <Col md={4} className="mb-2 align-self-center">
                <Row className="flex-row ms-md-1">
                    <Col md={5} className="p-md-0">
                        <DatePicker
                            id="from-date-picker"
                            name="from_date"
                            className={`form-control`}
                            placeholder="From date"
                            // defaultValue={moment().startOf('month')}
                            // allowClear={false}
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                });
                            }}
                            style={{minHeight: '2.5rem'}}
                        />
                        {/* <Form.Control
                            type="month"
                            name="start_date"
                            min={`${minSelectableYear}-01`}
                            max={`${new Date().getFullYear() + 1 }-12`}
                            size="lg"
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    start_date: event.target.value,
                                });
                            }}
                        /> */}
                    </Col>
                    <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0">
                        <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                            To
                        </Badge>
                    </Col>
                    <Col md={5} className="p-md-0">
                        <DatePicker
                            id="to-date-picker"
                            name="to_date"
                            className={`form-control`}
                            placeholder="To date"
                            // defaultValue={moment().endOf('month')}
                            // allowClear={false}
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                });
                            }}
                            style={{minHeight: '2.5rem'}}
                        />
                        {/* <Form.Control
                            type="month"
                            name="start_date"
                            min={`${minSelectableYear}-01`}
                            max={`${new Date().getFullYear() + 1}-12`}
                            size="lg"
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    end_date: event.target.value,
                                });
                            }}
                        /> */}
                    </Col>
                </Row>
            </Col>
            <Col md={2} className="mb-2 align-self-center d-flex justify-content-md-start justify-content-center">
                        <Button className="ms-2" size="lg" variant="info" onClick={() => search()}>
                            <Filter size={15} />
                        </Button>
                        <Button
                            className="float-end ms-2"
                            variant="info"
                            type="submit"
                            size="lg"
                            onClick={() => generatePayments()}
                        >
                            <FontAwesomeIcon icon={faFileExcel} />{" "}
                        </Button>
            </Col>
        </Row>
    );
};
export default Search;

import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import {
    addDefaultOption,
    calculateCommonSubtotal,
    commonBeforeGSTAmount,
    formatNumberWithCommas,
    roundToTwoDecimalDigits,

} from "../../../../../utils/utility";

import { Button, Col, Form, Row } from "react-bootstrap";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import { generateUUID } from "../../../../../utils/utility";

import EditCourseInformation from "./EditCourseInformation";
import { Plus, Trash } from "react-feather";
import Select from "react-select";

const EditIStudentItemRow = ({
    configValues,
    control,
    setValue,
    getValues,
    courseFee,
    errors,
    quotation,
    method,
    canChangeTradeCategory,
    setCanChangeTradeCategory,
    studentArrays,
    setStudentArrays,
    adhocCourseFees,
}) => {
    const { fields, append, insert, remove, update } = useFieldArray({
        control,
        name: "items",
    });

    const [subtotals, setSubtotals] = useState([]);
    const [originalSubtotals, setOriginalSubtotals] = useState([]);
    const [cumulativeSubtotal, setCumulativeSubtotal] = useState(0);
    const [gstAmount, setGstAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [noGST, setNoGST] = useState(false);
    const isDraft = method?.toUpperCase() == 'DRAFT-EDIT';
    const gstRate = parseFloat(configValues.gst);
    const [indexWithDiscount, setIndexWithDiscount] = useState([]);
    const [indexWithAdditional, setIndexWithAdditional] = useState([]);
    const [lastIndices, setLastIndices] = useState([]);
    const [originalAmounts, setOriginalAmounts] = useState([]);
    const [isPercentDiscount, setIsPercentDiscount] = useState([]);


    // Function for AdHoc Additional Items Dropdown
    const mapAdhocCourseFee = () => {
        let adHocType = [];       
        adhocCourseFees?.forEach((adhoc) => {
            adHocType.push({
                value: adhoc.id,
                label: `${adhoc.course_code} (${adhoc.item_name})`,
                fee: adhoc.fee,
                item_code: adhoc.course_code,

            });
        });
        return adHocType;
    }   
    const handleAdditionalChange = async (selectedOption, index) => {     
        // Update price and totals
        // setValue(`items[${index}].course_code`,{
        //     value: selectedOption.id,
        //     label: selectedOption.item_code
        // })
        updatePriceAndTotals(index, selectedOption?.fee, gstRate);
    };


    // Function to calculate the cumulative subtotal
    const calculateCumulativeSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateSubtotal(index);
            newCumulativeSubtotal += subtotal;
        });

        return newCumulativeSubtotal;
    };

    const calculateDiscountBasePercentage = (index, percentage) => {
        var coursePrice = parseFloat(getValues(`items[${index-1}].original_price`));
        var discount = coursePrice * (percentage / 100);
        setValue(`items[${index}].original_price`, discount?.toFixed(6));
        setValue(`items[${index}].price`, discount?.toFixed(6));
        updateAllSubtotals();
        updateTotals();
    };

    const calculateCumulativeOriginalSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateOriginalSubtotal(index);
            if (field.item_type !== "discount") {
                newCumulativeSubtotal += subtotal;
            }
        });

        return newCumulativeSubtotal;
    };

    // Function to calculate the cumulative subtotal
    const calculateCumulativeDiscountSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const price = parseFloat(getValues(`items[${index}].price`));
            const totalStudents = parseFloat(
                getValues(`items[${index}].total_student`)
            );
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            // Only add subtotals for "course" items
            if (field.item_type === "discount") {
                newCumulativeSubtotal += subtotal;
            }
        });

        return parseFloat(newCumulativeSubtotal);
    };

    // Function to update all totals
    const updateTotals = async () => {
        const newCumulativeSubtotal = calculateCumulativeSubtotal();
        let newCumulativeOriginalSubtotal =
            calculateCumulativeOriginalSubtotal();
        const newTotalDiscount = calculateCumulativeDiscountSubtotal();

        let totalAmount = newCumulativeOriginalSubtotal + newTotalDiscount;
        let newGstAmount = totalAmount - newCumulativeSubtotal;

        if (noGST) {
            newGstAmount = 0;
            totalAmount = newCumulativeSubtotal;
        }

        // Calculate the total amount as the difference between
        // the sum of all original amounts and the sum of all discount original amounts
        setCumulativeSubtotal(newCumulativeSubtotal);
        setGstAmount(roundToTwoDecimalDigits(newGstAmount));
        setTotalAmount(totalAmount);
        setTotalDiscount(newTotalDiscount);

        const roundedNewGstAmount = roundToTwoDecimalDigits(newGstAmount);
        const roundedNewCumulativeSubtotal = roundToTwoDecimalDigits(newCumulativeSubtotal);
        const roundedTotalAmount = roundToTwoDecimalDigits(totalAmount);
        
        const newValues = {
            'subtotals': roundedNewCumulativeSubtotal,
            'gst_amount': roundedNewGstAmount,
            'total_amount': roundedTotalAmount
        };

        if (method?.toUpperCase() == 'TRADE-CATEGORY-EDIT') {
            // Check if originalAmount (which is the original amount)
            if (originalAmounts.length !== 0 && areObjectsEqual(newValues, originalAmounts)) {
                setCanChangeTradeCategory(true);
            } else {
                setCanChangeTradeCategory(false);
            }
        }

        setValue("subtotals", newCumulativeSubtotal);
        setValue("gst_amount", roundToTwoDecimalDigits(newGstAmount));
        setValue("total_amount", totalAmount);
    };

    const areObjectsEqual = (obj1, obj2) => {
        return Object.keys(obj1).length === Object.keys(obj2).length &&
            Object.keys(obj1).every(key => obj1[key] === obj2[key]);
    };

    // Function to add item
    const addItem = () => {
        append({ uid: generateUUID(), total_student: 1, price: 0, item_type: "course" });
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };

    // Function to add discount row
    const addDiscountRow = (event) => {
        let parent = parseInt(event.target.getAttribute("data-index"));
        let index = parent + 1;  
        let totalStudents = fields[parent].total_student;

        // Use insert instead of append. The index is the index after the course_item of which the clicked button belongs to.
        insert(parseInt(index), {total_student: -totalStudents, price: 0, item_type: "discount",parent: fields[parent]?.uid,});
        // append({ total_student: -1, price: 0, item_type: "discount" });
        insertStudentArrayIndex(index);
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };

    const addAdditionalRow = (event) => {
        let parent = parseInt(event.target.getAttribute("data-index"));
        let index = parent + 1;  
        let totalStudents = fields[parent].total_student;

        const nextRow = fields[index];
        if(nextRow && nextRow.item_type == 'discount'){
            index++;
        }
        // Use insert instead of append. The index is the index after the course_item of which the clicked button belongs to.
        insert(parseInt(index), {
            total_student: -totalStudents,
            price: 0,
            item_type: "additional",
            parent: fields[parent]?.uid,
        });
        // append({ total_student: -1, price: 0, item_type: "discount" });
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };


    // Function to update price and totals
    // Function to update price and totals
    const updatePriceAndTotals = (index, fee, gstRate) => {
        setValue(`items[${index}].original_price`, fee);
        setValue(`items[${index}].price`, commonBeforeGSTAmount(fee, gstRate));

        updateSubtotal(index);
        updateTotals();
    };

    // Function to update course code selection on the item
    const updateCourseCode = (index, codeValue) => {
        const courseCodeOptionConfig = configValues?.course_code?.find(
            (option) => option.value === codeValue
        );
        setValue(`items[${index}].course_code`, courseCodeOptionConfig);
    };

    // Function to handle course code selection
    const handleCourseChange = async (courseCodeId, index) => {
        let courseInformationData = courseFee.find(
            (data) => data.id === courseCodeId
        );

        setValue(
            `items[${index}].trade_option`,
            configValues?.tradeOptions?.find(
                (data) => data.value === courseInformationData?.type_of_trade
            ) ?? []
        );
        setValue(
            `items[${index}].application_option`,
            configValues?.tradeApplication?.find(
                (data) =>
                    data.value === courseInformationData?.application_option
            ) ?? []
        );
        setValue(
            `items[${index}].trade_category`,
            configValues?.tradeCategory?.find(
                (data) => data.value === courseInformationData?.trade_category
            ) ?? []
        );
        setValue(
            `items[${index}].course`,
            configValues?.attcCourses?.find(
                (data) => data.value === courseInformationData?.course_id
            ) ?? []
        );
        // Update price and totals
        updatePriceAndTotals(index, courseInformationData?.fee, gstRate);
    };

    // Function to handle the dropdown selection trade option, application ,course and trade category
    const handleDropDownChange = (index) => {
        // Get the selected values from the form inputs
        let tradeOption = getValues(`items[${index}].trade_option`);
        let applicationOption = getValues(`items[${index}].application_option`);
        let course = getValues(`items[${index}].course`);
        let tradeCategory = getValues(`items[${index}].trade_category`);

        // Create a filter object for non-empty values
        let filterObject = {};

        if (tradeOption) {
            filterObject.type_of_trade = tradeOption?.value;
        }

        if (applicationOption) {
            filterObject.application_option = applicationOption?.value;
        }

        if (course) {
            filterObject.course_id = course?.value;
        }
        if (tradeCategory) {
            filterObject.trade_category = tradeCategory.value;
        }
        let courseFees = 0;
        updatePriceAndTotals(index, courseFees, gstRate);

        // Check if there are non-empty fields to filter
        if (Object.keys(filterObject).length > 0) {
            // Filter the courseFee array based on the non-empty fields
            let filteredCourseFee = courseFee.filter((data) => {
                for (let key in filterObject) {
                    if (data[key] !== filterObject[key]) {
                        return false;
                    }
                }
                return true;
            });
            let mergedData = {};

            filteredCourseFee.forEach((data) => {
                mergedData = { ...mergedData, ...data };
            });

            if (mergedData?.fee) {
                courseFees = mergedData?.fee;
            }
            updatePriceAndTotals(index, courseFees, gstRate);
            updateCourseCode(index, mergedData?.id);
        }
    };

    // Calculate subtotal for a specific item
    const calculateSubtotal = (index) => {
        const field = fields[index];
        const isDiscount = field?.item_type === "discount";

        const price = parseFloat(
            isDiscount
                ? commonBeforeGSTAmount(
                    getValues(`items[${index}].price`),
                    gstRate
                )
                : getValues(`items[${index}].price`)
        );

        const totalStudents = parseFloat(
            getValues(`items[${index}].total_student`)
        );
        if (!isNaN(totalStudents) && !isNaN(price)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    // Calculate subtotal for a specific item
    const calculateOriginalSubtotal = (index) => {
        const price = parseFloat(getValues(`items[${index}].original_price`));
        const totalStudents = parseFloat(
            getValues(`items[${index}].total_student`)
        );
        if (!isNaN(totalStudents)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    // function get the original price
    const getOriginalPrice = (index) => {
        let priceValue = getValues(`items[${index}].original_price`);
        if (
            isNaN(priceValue) ||
            priceValue === undefined ||
            priceValue === ""
        ) {
            return "";
        }
        return formatNumberWithCommas(priceValue);
    };

    // function get the before tax for Discount
    const discountBeforeGSTAmount = (index) => {
        let priceValue = getValues(`items[${index}].price`);
        if (
            isNaN(priceValue) ||
            priceValue === undefined ||
            priceValue === ""
        ) {
            return "";
        }
        return commonBeforeGSTAmount(priceValue, gstRate);
    };

    const updateSubtotal = (index) => {
        const newSubtotals = [...subtotals];
        const newOriginalSubtotals = [...originalSubtotals];
        newSubtotals[index] = calculateSubtotal(index);
        newOriginalSubtotals[index] = calculateOriginalSubtotal(index);
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);

        // Update all totals after changing the subtotal
        // updateTotals();
    };
    // Function to update all subtotals when "Apply no GST" is toggled
    const updateAllSubtotals = () => {
        const newSubtotals = fields.map((_, index) => calculateSubtotal(index));
        const newOriginalSubtotals = fields.map((_, index) =>
            calculateOriginalSubtotal(index)
        );
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);
        // Update all totals after toggling "Apply no GST"
        updateTotals();
    };

    const removeItem = (index) => {
        const parentId = fields[index].uid

        // Removes all the dependecies on the item row if the parent ID exsist
        // if not it means that only a certain discount/additional are removed
        if(parentId){
            const indicesToRemove = fields
            .map((field, index) => field.parent === parentId ? index : -1)
            .filter(index => index !== -1); // Get only valid indices
    
            // This is reversed to prevent the shifting of the index when removing on the loop
            indicesToRemove.reverse();
            indicesToRemove.forEach(indiceIndex => {
                remove(indiceIndex);
            });
        }
  
        remove(index);
        // Remove the item at the specified index from the studentArrays
        removeStudentArrayIndex(index);

        // When the course item with discount is removed.
        if (indexWithDiscount.includes(index)){
            //removing discount row of the deleted course item.
            // remove(index);
            // Remove the item at the specified index from the studentArrays
            removeStudentArrayIndex(index);
        }
        // Update the subtotal for the removed item
        const newSubtotals = [...subtotals];
        newSubtotals.splice(index, 1);
        setSubtotals(newSubtotals);
        setOriginalSubtotals([...originalSubtotals]);
    
        // Update all totals after removing the item
        updateTotals();
    };
    
    const removeStudentArrayIndex = (index) => {
        setStudentArrays(prevStudentArrays => {
            if (index < 0 || index >= prevStudentArrays.length) {
                // If index is out of bounds, just return the previous state
                return prevStudentArrays;
            }
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays.splice(index, 1);
                return newStudentArrays;
            });
    }

    const insertStudentArrayIndex = (index) => {
        // Insert an empty array into studentArrays at the same index
        const newStudentArrays = [...studentArrays];
        newStudentArrays.splice(index, 0, []);
        setStudentArrays(newStudentArrays);
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    }


    useEffect(() => {
        const fetchData = async () => {
            // Update GST-related values
            if (quotation && quotation?.gst_amount !== undefined && !noGST) {
                setValue("gst_amount", quotation.gst_amount);
                setGstAmount(quotation?.gst_amount);
            }
            // Update all subtotals when "Apply no GST" is toggled
            await updateAllSubtotals();
        };

        fetchData(); // Call the async function
    }, [noGST, fields]);

    useEffect(() => {
        // Update all subtotals when "Apply no GST" is toggled
        setTotalAmount(parseFloat(cumulativeSubtotal) + parseFloat(gstAmount));
    }, [gstAmount]);

    useEffect(() => {
        // Initialize the `no_gst` checkbox based on `quotation.no_gst`
        setNoGST(quotation?.no_gst || false);

        if (quotation) {
            setOriginalAmounts({
                'subtotals': parseFloat(quotation.subtotal),
                'gst_amount': parseFloat(quotation.gst_amount),
                'total_amount': parseFloat(quotation.total_amount)
            });
        }
    }, [quotation]); // Run this effect when `quotation` changes

    useEffect(() => {
        const withDiscount = fields.reduce((accumulator, value, index) => {
            if (value.item_type === 'discount') {
                // Since the discount row is always inserted after a course item, decrement index by 1.
                accumulator.push(index - 1);
            }
            return accumulator;
        }, []);
          
        // This is for the hiding the border below if its the last of the group items
        const calculateLastIndices = () => {
            const lastIndexes = fields.reduce((acc, field, index) => {
                const nextField = fields[index + 1];
                // If it's the last item in the entire array
                if (index === fields.length - 1) {
                    acc.push(index);
                } else if (field.item_type === "course") {
                    // A parent is last if the next item doesn't reference it
                    if (!nextField || nextField.parent !== field.uid) {
                        acc.push(index);
                    }
                } else if (
                    field.item_type === "additional" || 
                    field.item_type === "discount"
                ) {
                    // An additional or discount is last if the next item doesn't share the same parent
                    if (!nextField || nextField.parent !== field.parent) {
                        acc.push(index);
                    }
                }

                return acc;
            }, []);
            setLastIndices(lastIndexes);
        };
        setIndexWithDiscount(withDiscount);

        // Updating the total student of the discounts
        const newSubtotals = [...subtotals];
        const newOriginalSubtotals = [...originalSubtotals];
        const additionalIndices = [];
        fields.forEach((field, index) => {
            let totalStudents;
            let parentId = field.parent;
      
            if(parentId){
                const parentItem = fields.find(item => item.uid === parentId);
                totalStudents = parentItem?.total_student ?? null;
                if (field.item_type === "discount") {
                    totalStudents = -totalStudents;
                }
                if(field.item_type === 'additional'){
                    additionalIndices.push(field.parent);
                }
            }
            if (totalStudents !== undefined) {
                // Setting the discount's total_student value to negative.
                setValue(`items[${index}].total_student`, totalStudents);
                updateSubtotal(index);
            }
            // if (field.item_type === 'discount') {
            //     // Getting the value of the discount's parent course_item total_student.
            //     const totalStudents = getValues(`items[${index-1}].total_student`);
            //     // Setting the discount's total_student value.
            //     setValue(`items[${index}].total_student`, -totalStudents); // Value of the discount should always be negative.
                
            //     if (!isNaN(totalStudents) && !isNaN(field.price)) {
            //         const subtotal = calculateCommonSubtotal(commonBeforeGSTAmount(field.price,gstRate), -totalStudents);
            //         newSubtotals[index] = roundToTwoDecimalDigits(subtotal);
            //     }
                
            // } else {
            //     const totalStudents = getValues(`items[${index}].total_student`);
            //     const subtotal = calculateCommonSubtotal(field.price, totalStudents);
            //     newSubtotals[index] = roundToTwoDecimalDigits(subtotal);
            // }
            // newOriginalSubtotals[index] = calculateOriginalSubtotal(index);
        });
        setIndexWithAdditional(additionalIndices);
        updateAllSubtotals();
        calculateLastIndices();
        // setSubtotals(newSubtotals);
        // setOriginalSubtotals(newOriginalSubtotals);
        updateTotals();
    }, [fields])
    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th width="1%">#</th>
                        <th width="35%">Course information</th>
                        <th width="5%">Student</th>
                        <th width="10%">Price</th>
                        <th width="3%">Subtotal</th>
                        {isDraft && (
                            <th width="2%">Action</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {fields.length === 0 && (
                        <tr>
                            <td colSpan={6}>No items</td>
                        </tr>
                    )}
                    {fields.map((field, index) => (
                        <tr key={field.id} className={!(lastIndices.includes(index)) ? "border-bottom-0" : ''}>
                            <td style={{ verticalAlign: "top" }} className={!(lastIndices.includes(index)) ? "border-bottom-0" : ''}>
                                {index + 1}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={!(lastIndices.includes(index)) ? "border-bottom-0" : ''}>
                                {field.item_type === "course" && (
                                    <>
                                        <EditCourseInformation
                                            control={control}
                                            index={index}
                                            configValues={configValues}
                                            handleCourseChange={
                                                handleCourseChange
                                            }
                                            handleDropDownChange={
                                                handleDropDownChange
                                            }
                                            errors={errors}
                                            setValue={setValue}
                                            getValues={getValues}
                                            quotation={quotation}
                                            method={method}
                                            studentArrays={studentArrays}
                                            setStudentArrays={setStudentArrays}
                                            updateAllSubtotals={updateAllSubtotals}
                                        />
                                        {isDraft && (
                                            <>
                                                {!indexWithDiscount.includes(index) && (
                                                <Button
                                                    variant="secondary"
                                                    onClick={addDiscountRow}
                                                    className="mt-1 me-2"
                                                    data-index={index}
                                                >
                                                    <Plus size={15} /> Add discount
                                                </Button>
                                                )}
                                                { !indexWithAdditional.includes(field.uid) &&(
                                                    <Button
                                                    variant="secondary"
                                                    onClick={addAdditionalRow}
                                                    className="mt-1"
                                                    data-index={index}
                                                    >
                                                    <Plus size={15} /> Additional
                                                    </Button>
                                                )}
                                                
                                            </>
                                        )}
                                    </>
                                )}
                                {field.item_type === "discount" && (
                                    <Controller
                                        name={`items[${index}].discount_item`}
                                        control={control}
                                        defaultValue={
                                            "Less discount allowed" || ""
                                        }
                                        render={({ field }) => (
                                            <AutoGrowTextarea
                                                name={field.name}
                                                id={field.id}
                                                fieldValue={field.value}
                                                handleBlur={field.onBlur}
                                                isInvalid={
                                                    !!errors.discount_item
                                                }
                                                handleFormChangeDetails={
                                                    field.onChange
                                                }
                                                readOnly={!isDraft}
                                            />
                                        )}
                                    />
                                )}
                                  {field.item_type === "additional" && (
                                    <Controller
                                    name={`items[${index}].course_code`}
                                    control={control}
                                    defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                options={addDefaultOption(mapAdhocCourseFee())}
                                                classNamePrefix="react-select"
                                                id={field.id}
                                                name={field.name}
                                                handleBlur={field.onBlur}
                                           
                                                className={`react-select-container ${
                                                    errors.discount_item
                                                        ? "is-invalid"
                                                        : ""
                                                }`} // Add invalid class if there's an error
                                                value={field.value}
                                                defaultValue={field.value}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleAdditionalChange(
                                                        selectedOption,
                                                        index
                                                    );
                                                }}
                                                isDisabled={!isDraft}
                                            />
                                        )}
                                    />
                                )}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={!(lastIndices.includes(index)) ? "border-bottom-0" : ''}>
                                <Controller
                                    name={`items[${index}].total_student`}
                                    defaultValue={
                                        field.item_type === "discount" ? -1 : 1
                                    }
                                    control={control}
                                    render={({ field: inputProps }) => (
                                        <Form.Control
                                            type="number"
                                            {...inputProps}
                                            readOnly={field.item_type === "discount" || !isDraft}
                                            onChange={(e) => {
                                                const newValue = parseFloat(
                                                    e.target.value
                                                );
                                                if (
                                                    field.item_type ===
                                                    "discount" &&
                                                    newValue > 0
                                                ) {
                                                    // If it's a discount, and the value is positive, set it to negative
                                                    inputProps.onChange(
                                                        -newValue
                                                    );
                                                } else {
                                                    inputProps.onChange(
                                                        newValue
                                                    );
                                                }
                                                updateSubtotal(index);
                                                // Update the fields when the total_student of a course_item is changed.
                                                // this will inturn call the useEffect of fields to update the values of the
                                                // total_student of the course_item's respective discounts if it has any.
                                                update();
                                            }}
                                        />
                                    )}
                                />
                            </td>
                            <td style={{ verticalAlign: "top" }} className={!(lastIndices.includes(index)) ? "border-bottom-0" : ''}>
                                <Controller
                                    name={`items[${index}].original_price`}
                                    control={control}
                                    defaultValue={0}
                                    render={({ field: inputProps }) => (
                                        <>
                                            <Form.Control
                                                style={{ width: "100%" }}
                                                type="hidden"
                                                step="0.01"
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                />
                                {field.item_type === "discount" && (
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        value={discountBeforeGSTAmount(index)}
                                        readOnly={true}
                                    />
                                )}

                                <Row>
                                    {field.item_type === "discount" && (
                                        <Col md={6}>
                                            (<small>Enter after GST</small>)
                                        </Col>
                                    )}
                                    <Col
                                        md={
                                            field.item_type !== "discount"
                                                ? 12
                                                : 6
                                        }
                                    >
                                        <Controller
                                            name={`items[${index}].price`}
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: inputProps }) => (
                                                <>
                                                    <Form.Control
                                                        style={{
                                                            width:
                                                                field.item_type ===
                                                                    "discount"
                                                                    ? "100%"
                                                                    : "100%",
                                                        }}
                                                        type="number"
                                                        step="0.01"
                                                        readOnly={
                                                            field.item_type !==
                                                                "discount" || !isDraft || isPercentDiscount[index]
                                                        }
                                                        {...inputProps}
                                                        onChange={(e) => {
                                                            inputProps.onChange(
                                                                e
                                                            );
                                                            updateSubtotal(
                                                                index
                                                            );
                                                        }}
                                                        isDisabled={true}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Col>
                                </Row>

                                {field.item_type === 'discount' && (
                                    <>
                                        {isPercentDiscount[index] && (
                                            <Row>
                                                <Col md={6}>
                                                    <small className="me-2 text-nowrap">Discount %</small>
                                                </Col>
                                                <Col md={6} className="d-flex align-items-end">
                                                    <Controller
                                                        name={`items[${index}].percent_discount`}
                                                        control={control}
                                                        defaultValue={0}
                                                        render={({ field: inputProps }) => (
                                                            <>
                                                                <Form.Control
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    type="number"
                                                                    step="0.01"
                                                                    {...inputProps}
                                                                    onChange={(e) => {
                                                                        // Check if value is a number, then round to 2 decimal places
                                                                        let value = parseFloat(e.target.value ?? 0);
                                                                        if (!isNaN(value)) {
                                                                            // Round to 6 decimal places and convert back to string
                                                                            value = parseFloat(value.toFixed(6));
                                                                            inputProps.onChange({
                                                                                ...e,
                                                                                target: {
                                                                                    ...e.target,
                                                                                    value: value,
                                                                                },
                                                                            });
                                                                            calculateDiscountBasePercentage(index, value ?? 0);
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col md={6}>
                                                <small className="me-2 text-nowrap">Percent input</small>
                                            </Col>
                                            <Col md={6} className="d-flex align-items-end">
                                                <input
                                                    disabled={false}
                                                    type="checkbox"
                                                    name="is_discount_input"
                                                    defaultChecked={isPercentDiscount[index]} // assuming you are accessing the correct index of the array
                                                    onChange={(e) => {
                                                        // Create a new array with updated value at the given index
                                                        const updatedDiscounts = [...isPercentDiscount];
                                                        updatedDiscounts[index] = e?.target?.checked ?? false;
                                                        setIsPercentDiscount(updatedDiscounts); // Update state with new array
                                                        
                                                        setValue(`items[${index}].price`, 0);
                                                        setValue(`items[${index}].percent_discount`, 0);
                                                        updateAllSubtotals();
                                                        updateTotals();
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {field.item_type !== "discount" && (
                                    <small>
                                        After GST {getOriginalPrice(index)}
                                    </small>
                                )}
                            </td>

                            <td
                                style={{ verticalAlign: "top" }}
                                className={!(lastIndices.includes(index)) ? " currency border-bottom-0" : 'currency'}
                            >
                                {formatNumberWithCommas(subtotals[index])}
                            </td>
                            {isDraft && (
                                <td style={{ verticalAlign: "top", maxWidth: '20px'}} className={!(lastIndices.includes(index)) ? "border-bottom-0" : ''}>
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            removeItem(index);
                                        }}
                                    >
                                        <Trash size={10} />
                                    </Button>
                                </td>
                            )}

                        </tr>
                    ))}

                    <tr>
                        {isDraft && (
                            <td colSpan={2} className="border-0">
                                <Button
                                    variant="secondary"
                                    onClick={addItem}
                                    className="mt-1 mx-1 "
                                >
                                    <Plus size={15}></Plus> Add item
                                </Button>
                            </td>
                        )}
                        {!isDraft && (
                            <td colSpan={2} className="border-0">
                                &nbsp;
                            </td>
                        )}
                        {fields.length > 0 && (
                            <>
                                <td
                                    colSpan={2}
                                    className="text-end fs-4 border-0"
                                >
                                    Subtotal
                                </td>
                                <td className=" currency border-0">
                                    {formatNumberWithCommas(cumulativeSubtotal)}
                                </td>
                            </>
                        )}
                    </tr>
                    {fields.length > 0 && (
                        <>

                            <tr>
                                <td
                                    colSpan={4}
                                    className="text-end fs-4 border-0"
                                >
                                    Total tax amount{" "}
                                    {noGST ? 0 : configValues.gst}%
                                </td>
                                <td className=" border-0">
                                    <Controller
                                        name="gst_amount" // Name should match the field name in your form data
                                        control={control} // Use RHF control
                                        render={({ field }) => (
                                            <Form.Control
                                                type="number"
                                                readOnly={!isDraft}
                                                {...field}
                                                step="0.01"
                                                onChange={(e) => {
                                                    setGstAmount(
                                                        e.target.value
                                                    );
                                                    field.onChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td
                                    colSpan={4}
                                    className="text-end fs-4 border-0"
                                >
                                    Total amount
                                </td>
                                <td className="currency border-0">
                                    {formatNumberWithCommas(totalAmount)}
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>

            <Controller
                name="subtotals"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />

            <Controller
                name="total_amount"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />
        </div>
    );
};

export default EditIStudentItemRow;

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    useTable,
    usePagination,
    useSortBy,
    useBlockLayout,
    useRowSelect,
} from "react-table";
import {
    Table,
    Row,
    Col,
    Alert,
    Pagination,
    Button,
    Form,
    Card,
} from "react-bootstrap";
import api from "../../../service/api";
import { DeleteModal } from "../../../components/DeleteModal";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import ListButton from "./ListButton";
import { WithdrawModal } from "../../../components/WithdrawModal";
import {
    faFileExcel,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "antd";
import moment from "moment";
import { UserDateFormat } from "../../../utils/dateFormat";
import download from "downloadjs";
import { useSelector } from "react-redux";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { addDefaultOption, scrollButtonFunction } from "../../../utils/utility";
import DoubleScrollbar from "react-double-scrollbar";
import { ReEnrollModal } from "../../../components/ReEnrollModal";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { ConfirmModal } from "../../../components/ConfirmModal";
import { debounce, filter } from 'lodash';
import { handleCalculateTop, handleStickyHeaderObserver, stickyHeaderResizeListener } from "../../../utils/stickyHeaderHelper";

const Lists = ({
    columns,
    rawData,
    enrolmentApi,
    status,
    searchValue,
    setRerender,
    rerender,
    filterStickyComponent,
}) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    const params = useParams();
    const [customStatusOptions, setCustomStatusOptions] = useState([])
    const [customStatus, setCustomStatus] = useState(null)
    const [customStatusRemarks, setCustomStatusRemarks] = useState(null)
    const [selectedEnrolments, setSelectedEnrolments] = useState([])
    const [selectedStudentRows, setSelectedStudentRows] = useState([])

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [modalWithdraw, setModalWithdraw] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
        fromSummary: false,
    });

    const [modalReEnroll, setModalReEnroll] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [statusModalInfo, setStatusModalInfo] = useState({
        size: 'sm',
        notifMsg: "",
        open: false,
        severity: "danger",
        static: false,
    });

    const stickyConfirmationHeader = {
        backgroundColor: '#f2f2f2',
        position: 'sticky',
        top: 0,
        zIndex: 1
    }

    const [hasMore, setHasMore] = useState(true);

    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const getStatusResultOptions = useCallback(async () => {
        try{
            setLoading(true)
            setIsSubmitting(true)
            const result = await api.get('tms/enrolments/get-config-result-enrolments');
            setCustomStatusOptions(result.data?.options)
            setLoading(false)
            setIsSubmitting(false)
        }catch(error){
            console.error(error)
            setNotif({
                notifMsg: 'Error in retrieving options',
                open: true,
                severity: "error",
            })
            setLoading(false)
            setIsSubmitting(false)
        }
    }, []);

    const popConfirmationModal = () => {
        const parse = list(selectedStudentRows, true)
        setStatusModalInfo({
            open: true,
            notifMsg: parse,
            severity: 'success',
            values: null,
            size: 'lg',
            isStatic: false,
        })
    }

    const customUpdateStatus = async () => {
        try{
            setLoading(true)
            setIsSubmitting(true)
            const payload = {
                'ids': selectedEnrolments,
                'status': customStatus,
                'remarks': customStatusRemarks,
            }

            const response = await api.post('tms/enrolments/update-custom-status', payload);
            if(response.status){
                const parse = list(response?.data, false)
                setStatusModalInfo({
                    open: true,
                    notifMsg: parse,
                    severity: 'success',
                    values: null,
                    size: 'lg',
                    isStatic: true,
                    refresh: true
                })
                setNotif({
                    notifMsg: response?.message,
                    open: true,
                    severity: "success",
                })
            }else{
                setNotif({
                    notifMsg: response?.message,
                    open: true,
                    severity: "danger",
                })
            }
            setLoading(false)
            setIsSubmitting(false)
        }catch(error){
            console.error(error)
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            })
            setLoading(false)
            setIsSubmitting(false)
        }
    };

    const [loading, setLoading] = useState(true);

    const [exportSearchValue, setExportSearchValue] = useState({
        company: '',
        from_date: '',
        to_date: ''
    });

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;
    
            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);
    
            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            );
        }
    );

    const list = (array, preview = false) => {
        if(Object.keys(array)?.length > 0){
            return (
            <div className="row table-responsive"  style={{maxHeight: '470px'}}>
                <h3>Summary of Status Changes</h3>
                <h5 className="text-start">{`Total Count of Selected: ${Object.keys(array)?.length}`}</h5>
                <Table className="table">
                    <thead>
                        <tr>
                            <th style={stickyConfirmationHeader}>Student Name</th>
                            <th style={stickyConfirmationHeader}>Fin</th>
                            <th style={stickyConfirmationHeader}>{`${preview  ? 'Current Status' : 'Old Status'}`}</th>
                            {!preview && (
                            <th style={stickyConfirmationHeader}>New Status</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(array)?.map((data, id) => {
                            return (
                                <tr key={id}>
                                    <td>{data?.name ?? ''}</td>
                                    <td>{data?.fin ?? ''}</td>
                                    <td>{!preview ? (data?.old_status ?? '') : data?.status}</td>
                                    {!preview && (
                                        <td>{data?.new_status ?? ''}</td>
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            )
        }
        return null
    }

    //determine whether the page is summary or other listing
    useEffect(() => {
        if(status === 'summary'){
            getStatusResultOptions();
        }
    }, [status])
    
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { meta, links, data } = rawData;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        selectedFlatRows,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null,
                canPreviousPage: links?.prev !== null,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination,
        useBlockLayout,
        useRowSelect,
        status === 'summary' && ((hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                                hidden={status !== 'summary'}
                                disabled={status !== 'summary'}
                            />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                                hidden={row.original.cannot_change_status || status !== 'summary'}
                                disabled={row.original.cannot_change_status || status !== 'summary'}
                            />
                        </div>
                    ),
                    sticky: "left",
                    width: '50'
                },
                ...columns,
            ]);
        }),
        useSticky
    );

    const gotoPage = (page) => {
        enrolmentApi({
            page,
        });
    };

    const deleteCompany = async (id) => {
        try {
            await api.delete(`tms/enrolments/${id}`, {});
            setNotif({
                id: id,
                notifMsg: "Deleted successfully",
                open: true,
                severity: "success",
            });
            enrolmentApi();
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const withdrawEnrolment = async (id, data, fromSummary) => {
        try {
            await api.post(`tms/enrolments/withdrawal/${id}/${fromSummary}`, data);
            setNotif({
                id: id,
                notifMsg: "Withdrawal successfully",
                open: true,
                severity: "success",
            });
            setRerender(!rerender);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const reEnrollEnrolment = async (id, data) => {
        try {
            await api.post(`tms/enrolments/enroll_student/${id}`, data);
            setNotif({
                id: id,
                notifMsg: "Enrolled successfully",
                open: true,
                severity: "success",
            });
            setRerender(!rerender);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };

    const openWithdrawModal = (id, fromSummary) => {
        setModalWithdraw({
            id: id,
            notifMsg: "",
            open: true,
            severity: "danger",
            fromSummary: fromSummary
        });
    };

    const openReEnrollModal = (id) => {
        setModalReEnroll({
            id: id,
            notifMsg: "Are you sure you want to enroll this item?",
            open: true,
            severity: "danger",
        });
    };

    const [error, setError] = useState();

    const generateEnrolmentReports = async () => {
        setIsSubmitting(true);
        try {
            const response = await api.getexcel(
                "tms/export-excel-enrolment",
                exportSearchValue
            );
            if (response.type.includes('application/json')) {
                const jsonData = JSON.parse(await response.text());
                // console.log(jsonData);
                if (jsonData.success){
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "success",
                    });
                }
                else{
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "danger",
                    });
                }
                setError(null)
            }else if (response.size !== 0) {
                let currentDate = new Date();
                let formattedDate = currentDate.toISOString().replace(/[-T:]/g, "_").slice(0, -5); // Format the date as YYYY_MM_DD_HH_mm_ss
                download(response, "enrolment_report_"+formattedDate, "application/xlsx");
                setError(null)
            } else {
                setError("No data to be exported.");
            }
        } catch (error) {
            setError(error?.response?.data?.message);
        }
        setLoading(false);
        setIsSubmitting(false);
    };

    useEffect(() => {
        const selectedRows = selectedFlatRows.map((row) => row.original.id);
        const studentRows = selectedFlatRows.map((row) => {
            return {
                name: row.original?.name,
                fin: row.original?.fin,
                status: row.original?.status
            }
        });
        setSelectedEnrolments(selectedRows)
        setSelectedStudentRows(studentRows)
    }, [selectedFlatRows])

    const DateFormat = UserDateFormat();

    // const Styles = styled.div`
    //     .table {
    //         .th,
    //         .td {
    //             background-color: #fff;
    //             overflow: hidden;
    //             border-top: none;
    //         }

    //         &.sticky {
    //             overflow: scroll;
    //             .header,
    //             .footer {
    //                 position: sticky;
    //                 z-index: 1;
    //                 width: fit-content;
    //             }
    //         }
    //     }
    //     .table > :not(:first-child) {
    //         border-top: none;
    //     }
    // `;

    const mainTableRef = useRef(null);
    const stickyTableRef = useRef(null);

    const tableHeaderRef = useRef(null);
    const [headerStyles, setHeaderStyles] = useState([]);
    const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false); // Flag to track if sticky class is applied
    const [styleTop, setStyleTop] = useState(0);
    const navigationStickyComponent = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            handleCalculateTop(filterStickyComponent, setStyleTop);
        });
    }, [])

    useEffect(() => {
        stickyHeaderResizeListener(setHeaderStyles, tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop);
    }, [data]);

    useEffect(() => {
        let currentRootMargin = isStickyHeaderVisible ? "-350px 0px 0px 0px" : "-250px 0px 0px 0px";
        if(status === 'report'){
            currentRootMargin = isStickyHeaderVisible ? "-550px 0px 0px 0px" : "-400px 0px 0px 0px";
        }

        handleStickyHeaderObserver(isStickyHeaderVisible, tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop, currentRootMargin, setIsStickyHeaderVisible, setHeaderStyles, stickyTableRef, mainTableRef);
    }, [isStickyHeaderVisible]);

    return (
        <>
                    <Row 
                        className={`${status === 'report' ? 'fixed-header-filter-enrollments' : 'fixed-header-w-navbar'}`} 
                        // ref={lowestStickyComponent}
                        style={{ top: styleTop }}
                    >
                        <Row>
                                {error && (
                                    <Card.Header>
                                        <Alert className="my-3" variant="danger">
                                            <div className="alert-message">{error}</div>
                                        </Alert>
                                    </Card.Header>
                                )}
                                <Col md={
                                        status === "summary" &&
                                        permission?.includes(
                                            "ENROLMENT-REPORT-SUMMARY"
                                        )
                                            ? 6
                                            : (totalPage > 1 ? 6 : 12)
                                    }
                                    xs={12}>
                                    <div className="scroll-buttons d-flex justify-content-center justify-content-md-start my-3">
                                        <Button
                                            onClick={scrollButtonFunction('.table', false, "auto") }
                                            variant="secondary"
                                            size="md"
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowAltCircleLeft}
                                            />
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            size="md"
                                            onClick={scrollButtonFunction('.table', true,  "auto") }
                                            className="mx-3 info"
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowAltCircleRight}
                                            />
                                        </Button>
                                    </div>
                                </Col>
                                {totalPage > 1 && (
                                    <Col
                                        md={
                                            status === "summary" &&
                                            permission?.includes(
                                                "ENROLMENT-REPORT-SUMMARY"
                                            )
                                                ? 6
                                                : 6
                                        }
                                        xs={12}
                                        className="d-flex justify-content-center justify-content-md-end"
                                    >
                                        <Pagination className="float-end my-auto">
                                            <Pagination.First
                                                onClick={() =>
                                                    gotoPage(firstPage)
                                                }
                                                disabled={!canPreviousPage}
                                            />
                                            <Pagination.Prev
                                                onClick={(i) =>
                                                    gotoPage(currentPage - 1)
                                                }
                                                disabled={!canPreviousPage}
                                            />
                                            <Pagination.Next
                                                onClick={() =>
                                                    gotoPage(currentPage + 1)
                                                }
                                                disabled={!canNextPage}
                                            />
                                            <Pagination.Last
                                                onClick={() =>
                                                    gotoPage(lastPage)
                                                }
                                                disabled={!canNextPage}
                                            />
                                        </Pagination>
                                        <span className="mx-2 float-end my-auto">
                                            Page {""}
                                            <strong>
                                                {currentPage} of {totalPage}
                                            </strong>
                                        </span>
                                    </Col>
                                )}
                        </Row>
                        {totalPage > 1 && (
                            <Col md={12} ref={navigationStickyComponent}>
                                <Row>
                                    {(status === "summary" || status === 'report') &&
                                        permission?.includes(
                                            "ENROLMENT-REPORT-SUMMARY"
                                        ) && (
                                            <>
                                            <Row>
                                                <Col md={3} className="my-md-0 my-3">
                                                    <AsyncPaginate
                                                        isClearable
                                                        loadOptions={handleLoadMore}
                                                        hasMore={hasMore}
                                                        placeholder="Search company"
                                                        additional={{
                                                            page: 1,
                                                        }}
                                                        onChange={(value) => {
                                                            setExportSearchValue({
                                                                ...exportSearchValue,
                                                                company: value
                                                                    ? value.map(item => item.value) : "",
                                                            });
                                                        }}
                                                        styles={{
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                        isMulti
                                                        debounceTimeout={1000}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <DatePicker
                                                            name="from_date"
                                                            selected={
                                                                new Date(
                                                                    moment(
                                                                        DateFormat?.toUpperCase()
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    )
                                                                )
                                                            }
                                                            className={`form-control`}
                                                            onChange={(
                                                                dates
                                                            ) => {
                                                                setExportSearchValue({
                                                                    ...exportSearchValue,
                                                                    from_date:
                                                                        dates ===
                                                                        null
                                                                            ? null
                                                                            : moment(
                                                                                  dates
                                                                              ).format(
                                                                                  "YYYY-MM-DD"
                                                                              ),
                                                                });
                                                            }}
                                                            dateFormat={
                                                                DateFormat
                                                            }
                                                            placeholder="From date"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <DatePicker
                                                            name="to_date"
                                                            selected={
                                                                new Date(
                                                                    moment(
                                                                        DateFormat?.toUpperCase()
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    )
                                                                )
                                                            }
                                                            className={`form-control`}
                                                            onChange={(
                                                                dates
                                                            ) => {
                                                                setExportSearchValue({
                                                                    ...exportSearchValue,
                                                                    to_date:
                                                                        dates ===
                                                                        null
                                                                            ? null
                                                                            : moment(
                                                                                  dates
                                                                              ).format(
                                                                                  "YYYY-MM-DD"
                                                                              ),
                                                                });
                                                            }}
                                                            dateFormat={
                                                                DateFormat
                                                            }
                                                            placeholder="To date"
                                                            maxDate={moment().toDate()}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    {!isSubmitting && (
                                                        <Button
                                                            className="float-start"
                                                            variant="info"
                                                            type="submit"
                                                            size="lg"
                                                            onClick={() =>
                                                                generateEnrolmentReports()
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faFileExcel}
                                                            />
                                                        </Button>
                                                    )}

                                                    {isSubmitting && (
                                                        <div class="spinner-border float-start" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                    
                                                </Col>
                                            </Row>
                                            </>
                                        )}
                                </Row>
                            </Col>
                        )}
                        {(status === "summary") &&
                            permission?.includes(
                                "ENROLLMENT-ACCESS-KIV"
                            ) && (
                            <Row className="my-3 d-flex">
                                {selectedEnrolments.length > 0 && (
                                    <Col className="d-flex gap-3">
                                        <Col md={6}>
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={addDefaultOption(
                                                    customStatusOptions
                                                )}
                                                onChange={(e) => {
                                                    setCustomStatus(
                                                        e?.value
                                                    );
                                                }}
                                                disabled={isSubmitting}
                                                name="result_status"
                                                isClearable={true}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                size="lg"
                                                name="remarks"
                                                onChange={(e) =>
                                                    setCustomStatusRemarks(e.target?.value)
                                                }
                                                placeholder="Remarks..."
                                            />
                                        </Col>
                                    </Col>
                                )}
                                {selectedFlatRows.length > 0 && !isSubmitting && (
                                    <Col md={3}>
                                        <Button
                                            className="float-start"
                                            variant="info"
                                            type="submit"
                                            size="lg"
                                            onClick={() =>
                                                popConfirmationModal()
                                            }
                                            placeholder="Select status"
                                        >
                                            <FontAwesomeIcon
                                                icon={faFlagCheckered}
                                            />
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        )}

                        {isStickyHeaderVisible && (status === 'report') && (
                            <div className="mx-0 sticky-header-container">
                                <div className="double-scrollbar-table-style">
                                {/* <Styles> */}
                                <div className="table-responsive">
                                    <DoubleScrollbar>
                                        <br></br>
                                        <Table  striped bordered className="table-target-for-scroll" ref={stickyTableRef} {...getTableProps()} style={{ overflowX: "hidden" }}>
                                            <thead>
                                                {headerStyles?.map((style, index) => (
                                                    <tr style={style} key={index}>
                                                        {style.headerChildren?.map((tdStyle, tdIndex) => (
                                                            <th key={tdIndex} style={tdStyle}>
                                                                {headerGroups[0]?.headers[tdIndex]?.render("Header")}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                        </Table>
                                    </DoubleScrollbar>
                                </div>
                                {/* </Styles> */}
                                </div>
                            </div>
                        )}
                    </Row>
            {data.length > 0 && (
                <Row>
                    <div className="double-scrollbar-table-style">
                        {/* <Styles> */}
                        <div className="table-responsive">
                            <DoubleScrollbar>
                            <br></br>
                            <Table
                                striped
                                bordered
                                {...getTableProps()}
                                className="table-target-for-scroll"
                                ref={mainTableRef}
                            >
                                <thead className="fixed-table-header" ref={tableHeaderRef}>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                            className="tr"
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        width={column.width}
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        className="th"
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="body"
                                >
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                className="tr"
                                            >
                                                {row.cells.map(
                                                    (cell, index, row) => {
                                                            if(status === 'summary'){
                                                            if (index === 1) {
                                                                return (
                                                                    <td
                                                                        {...cell.getCellProps()}
                                                                        key={index}
                                                                        className="td"
                                                                    >
                                                                        {currentPage >
                                                                        1
                                                                            ? (currentPage -
                                                                                    1) *
                                                                                    pageSize +
                                                                                i +
                                                                                1
                                                                            : i + 1}
                                                                    </td>
                                                                );
                                                            }
                                                            if (index === 2) {
                                                                return (
                                                                    <td
                                                                        {...cell.getCellProps()}
                                                                        key={index}
                                                                        className="td"
                                                                    >
                                                                        <ListButton
                                                                            status={
                                                                                status
                                                                            }
                                                                            id={
                                                                                cell
                                                                                    .row
                                                                                    .original
                                                                                    .id
                                                                            }
                                                                            openDeleteModal={
                                                                                openDeleteModal
                                                                            }
                                                                            openWithdrawModal={
                                                                                openWithdrawModal
                                                                            }
                                                                            openReEnrollModal={
                                                                                openReEnrollModal
                                                                            }
                                                                            cell={
                                                                                cell
                                                                            }
                                                                            searchValue={
                                                                                searchValue
                                                                            }
                                                                            enrollStudent={
                                                                                reEnrollEnrolment
                                                                            }
                                                                        />
                                                                    </td>
                                                                );
                                                            }
                                                        }else{
                                                            if (index === 0) {
                                                                return (
                                                                    <td
                                                                        {...cell.getCellProps()}
                                                                        key={index}
                                                                        className="td"
                                                                    >
                                                                        {currentPage >
                                                                        1
                                                                            ? (currentPage -
                                                                                    1) *
                                                                                    pageSize +
                                                                                i +
                                                                                1
                                                                            : i + 1}
                                                                    </td>
                                                                );
                                                            }
                                                            if (index === 1) {
                                                                return (
                                                                    <td
                                                                        {...cell.getCellProps()}
                                                                        key={index}
                                                                        className="td"
                                                                    >
                                                                        <ListButton
                                                                            status={
                                                                                status
                                                                            }
                                                                            id={
                                                                                cell
                                                                                    .row
                                                                                    .original
                                                                                    .id
                                                                            }
                                                                            openDeleteModal={
                                                                                openDeleteModal
                                                                            }
                                                                            openWithdrawModal={
                                                                                openWithdrawModal
                                                                            }
                                                                            openReEnrollModal={
                                                                                openReEnrollModal
                                                                            }
                                                                            cell={
                                                                                cell
                                                                            }
                                                                            searchValue={
                                                                                searchValue
                                                                            }
                                                                            enrollStudent={
                                                                                reEnrollEnrolment
                                                                            }
                                                                        />
                                                                    </td>
                                                                );
                                                            }
                                                        }

                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                className="td"
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            </DoubleScrollbar>
                        </div>
                        {/* </Styles> */}
                    </div>
                </Row>
            )}

            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No enrolment</div>
                </Alert>
            )}
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteCompany}
            />
            <WithdrawModal
                modalInfo={modalWithdraw}
                setModalInfo={setModalWithdraw}
                api={withdrawEnrolment}
            />
            <ReEnrollModal
                modalInfo={modalReEnroll}
                setModalInfo={setModalReEnroll}
                api={reEnrollEnrolment}
            />
            <ConfirmModal
                modalInfo={statusModalInfo}
                setModalInfo={setStatusModalInfo}
                api={customUpdateStatus}
                refreshApi={enrolmentApi}
            />
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };

import { Alert } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";

import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "../../../../../service/api";

import EditStudentRowForm from "./EditStudentRowForm";
const EditQuotationStudent = () => {
    let { method, id } = useParams();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [quotation, setQuotation] = useState();
    const [courseFee, setCourseFee] = useState();
    const [adhocCourseFees, setAdhocCourseFees] = useState();
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        atoCourses: [],
        attcCourses: [],
        companies: [],
        departments: [],
        location: [],
    });

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATO";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATTC";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const { atoCourses, attcCourses } = { ...configValues };
        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };



    /** use effect */
    useEffect(() => {
        const getConfig = async () => {
            try {
                setLoading(true);
                const configResponse = await api.get(
                    `tms/sales-quotations/config`,
                    {}
                );
                const courseResponse = await api.get(`tms/courses?all=1`, {});
                const courseFeeResponse = await api.get("tms/get_course_fee");
                const adhocCourseFeeResponse = await api.get("tms/get_course_fee/additional-items");
                setAdhocCourseFees(adhocCourseFeeResponse?.data);


                setCourseData(courseResponse?.data);
                setCourseFee(courseFeeResponse.data);
                setConfigValues({ ...configValues, ...configResponse.data });
                setLoading(false);
            } catch (error) {
                setError(error.message);
            }
        };
        if (id && id !== "add") {
            const getQuotation = async () => {
                try {
                    setLoading(true);
                    const response = await api.get(
                        `tms/sales-quotations/${id}`,
                        {}
                    );
                    setQuotation(response.data);
                    setLoading(false);
                } catch (error) { }
            };
            getQuotation();
        }
        getConfig();
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps

    const helmetTitle = "Quotation " + method; 

    return (
        <React.Fragment>
            <Helmet title={helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">
                    {/* Replacing all hyphen with space and then setting the first character as uppercase */}
                    {method[0].toUpperCase() + method.replace(/-/g, ' ').slice(1)} quotation
                </h1>
                <Card className="m-0 p-0">
                    <Card.Body>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        {loading && <div>Loading...</div>}
                        {!loading && (
                            <EditStudentRowForm
                                adhocCourseFees={adhocCourseFees}
                                configValues={configValues}
                                quotation={quotation}
                                courseFee={courseFee}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default EditQuotationStudent;

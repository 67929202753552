import React, { useState } from "react";
import { Badge, Button, Col, Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Filter, Plus } from "react-feather";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import api from "../../../../../service/api";
import { addDefaultOption } from "../../../../../utils/utility";
import { minSelectableYear } from "../../../../../utils/EnrolmentSummaryConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";

const Search = ({ setSearchValue, companies, searchValue, search, generateInvoice, generateInvoice2, salesPersons}) => {
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };
    return (
        <div className="py-3">
            <Row className="g-2 mb-2">
                <Col md={3} >
                    <Select
                        isClearable
                        className="react-select-container render-above-1"
                        classNamePrefix="react-select"
                        placeholder="Invoice Type"
                        options={addDefaultOption([
                            {
                                value: "AD-HOC",
                                label: "Ad-hoc invoice",
                            },
                            {
                                value: "ADDITIONAL",
                                label: "Additional invoice",
                            },
                            {
                                value: "SALES",
                                label: "Sales invoice",
                            }
                        ])}
                        name="invoice_type"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                invoice_type: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={3} >
                    <Form.Control
                        type="text"
                        name="number"
                        placeholder="Invoice no"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                number: event.target.value,
                            });
                        }}
                    />
                </Col>
                <Col md={3} >
                    <Select
                        isClearable
                        className="react-select-container render-above-1"
                        classNamePrefix="react-select"
                        options={addDefaultOption([
                            {
                                value: "CANCELLED",
                                label: "Cancelled",
                            },
                            {
                                value: "OVERPAID",
                                label: "Overpaid",
                            },
                            {
                                value: "PAID",
                                label: "Paid",
                            },
                            {
                                value: "PARTIAL-PAID",
                                label: "Partial paid",
                            },
                            {
                                value: "PENDING",
                                label: "Pending",
                            },
                        ])}
                        name="status"
                        placeholder="Payment Status"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                status: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={3} >
                    <AsyncPaginate
                        className="render-above"
                        isClearable
                        loadOptions={handleLoadMore}
                        hasMore={hasMore}
                        placeholder="Search company"
                        additional={{
                            page: 1,
                        }}
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                company: value ? value?.value : "",
                            });
                        }}
                        debounceTimeout={1000}
                    />
                </Col>
            </Row>

            <Row className="row-flex g-2">
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container render-above"
                        classNamePrefix="react-select"
                        options={addDefaultOption(salesPersons)}
                        name="salesperson"
                        placeholder="Search salesperson"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                salesperson: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container render-above"
                        classNamePrefix="react-select"
                        options={addDefaultOption([
                            {
                                value: 'FALSE',
                                label: "Not enrolled",
                            },
                            {
                                value: 'TRUE',
                                label: "Enrolled",
                            },
                        ])}
                        name="enrollment_status"
                        placeholder="Enrollment Status"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                enrollment_status: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Row className="flex-row flex-row ms-md-1">
                        <Col md={5} sm={12} className="p-md-0">
                            <DatePicker
                                id="from-date-picker"
                                name="from_date"
                                className={`form-control`}
                                placeholder="From date"
                                // defaultValue={moment().startOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    console.log(moment(event).format("YYYY-MM-DD"));
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1 }-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                        <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0">
                            <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                To
                            </Badge>
                        </Col>
                        <Col md={5} className="p-md-0">
                            <DatePicker
                                id="to-date-picker"
                                name="to_date"
                                className={`form-control`}
                                placeholder="To date"
                                // defaultValue={moment().endOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    console.log(moment(event).format("YYYY-MM-DD"), event);
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1}-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className="my-2">
                    <Col md={6}>
                        <Form.Control
                            type="text"
                            name="number"
                            placeholder="Enter starting invoice number"
                            size="lg"
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    other_invoice: event.target.value,
                                });
                            }}
                        />
                    </Col>
                </Col>
                <Col md={6} className="my-2 d-flex justify-content-md-end justify-content-center p-0">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Search filter</Tooltip>}
                    >
                        <Button className="" size="lg" variant="info" onClick={() => search()} title="Search filter">
                            <Filter size={15} />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Excel normal format</Tooltip>}
                    >
                        <Button
                            className="ms-2"  
                            variant="info"
                            type="submit"
                            size="lg"
                            title="Excel normal format"
                            onClick={() => generateInvoice()}
                        >
                            <FontAwesomeIcon icon={faFileExcel} />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Excel SAP format</Tooltip>}
                    >
                        <Button
                            className="ms-2"  
                            variant="info"
                            type="button"
                            size="lg"
                            title="Excel SAP format"
                            onClick={() => generateInvoice(true, 1)}
                        >
                            <FontAwesomeIcon icon={faFileAlt} />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Excel SAP format 2</Tooltip>}
                    >
                        <Button
                            className="ms-2"  
                            variant="info"
                            type="button"
                            size="lg"
                            title="Excel SAP format 2"
                            onClick={() => generateInvoice2(true, 2)}
                        >
                            <FontAwesomeIcon icon={faFileExport} />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    );
};
export default Search;

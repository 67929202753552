import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import SearchEnrolment from "../CommonComponents/SearchEnrolment";
import { Card, Container, Alert, Button, Col, Row } from "react-bootstrap";
import { tableColumns } from "./Data.js";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import api from "../../../service/api";
import { useSelector } from "react-redux";
import { Plus } from "react-feather";
import { Lists } from "./List";
import { summaryTableColumns } from "./SummaryData.js";

const Enrolment = ({ status, title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const { state } = useLocation();
    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;
    const [rerender, setRerender] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const searchFields = {
        name: "",
        fin: "",
        date: "",
        registration_type: "",
        status: status.toLowerCase() === 'report' ? 'summary' : status,
        type: "attc",
        trade_category: "",
        type_of_trade: "",
        application_option: "",
        course_id: "",
        company_id: "",
        result_date: "",
        hide_cet: false,
        invoice_no: "",
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getEnrolments(searchParam);
    };
    const resetSearch = () => {
        setSearchValue(searchFields);
        getEnrolments(searchFields);
    };
    const getEnrolments = useCallback(async (search) => {
        setLoading(true);
        try {
            const response = await api.get("tms/enrolments", search);
            setData(response);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }

        setLoading(false);
    }, []);

    //
    // useEffects
    //

    useEffect(() => {
        getEnrolments(searchValue);
    }, [getEnrolments, rerender]); //  eslint-disable-line react-hooks/exhaustive-deps
    const getConfig = useCallback(async () => {
        const configResponse = await api.get(`tms/enrolments/config`, {});
        setDefaultConfig(configResponse.data);
    }, []);
    useEffect(() => {
        getConfig();
    }, [getConfig]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps
    const filterStickyComponent = useRef(null); // Reference for the filter component


    const helmetTitle = status?.toUpperCase() === "REPORT" ? "Reports > Enrolment summary" : "Enrolments > " + title.charAt(0).toUpperCase() + title.slice(1);

    return (
        <React.Fragment>
            
            <Helmet title={helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Enrolment - {title}</h1>
                <Card>
                    <Card.Header className="p-0">
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        {status !== 'report' && (
                            <Row className='mx-1 pb-3 z-index-100'>
                                <Col xs={12} className="p-0">
                                    <SearchEnrolment
                                        search={search}
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        defaultConfig={defaultConfig}
                                        resetSearch={resetSearch}
                                        attcTestDate
                                    />
                                </Col>
                            </Row>
                        )}
                        
                        <Row>
                            {status === "enrolled" &&
                                permission?.includes("ENROLLMENT-CREATE") && (
                                    <Col md={12}>
                                        <Button
                                            size="lg"
                                            variant="success"
                                            className="me-1 float-end mb-2"
                                            onClick={() =>
                                                navigate("/tms/enrolments/add")
                                            }
                                        >
                                            <Plus
                                                className="feather"
                                                size={15}
                                            />{" "}
                                            Add
                                        </Button>
                                    </Col>
                                )}
                        </Row>
                    </Card.Header>
                    <Card.Body className="mx-1 p-0">
                        {status === 'report' && (
                            <Row className='p-3 fixed-header-filter' ref={filterStickyComponent}>
                                <Col xs={12} className="p-0">
                                    <SearchEnrolment
                                        search={search}
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        defaultConfig={defaultConfig}
                                        resetSearch={resetSearch}
                                        attcTestDate
                                    />
                                </Col>
                            </Row>
                        )}
                        {loading && <div>Loading...</div>}
                        {!loading && data?.data && (
                            <Lists
                                columns={ (title == "summary") ? summaryTableColumns : tableColumns}
                                rawData={data}
                                enrolmentApi={search}
                                status={status}
                                searchValue={searchValue}
                                rerender={rerender}
                                setRerender={setRerender}
                                filterStickyComponent={filterStickyComponent}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default Enrolment;



// You need to pass the reference of the main table that you want to copy the headers of.
// This will return the styles, width, etc of each header cell.
export const syncHeaderStyles = (tableHeaderRef) => {
    const tableHeader = tableHeaderRef.current;
    if (tableHeader) {
        const trs = Array.from(tableHeader.querySelectorAll("tr"));
        const trStyles = trs.map((tr) => {
            // console.log("CHILDREN", tr.children);
            const trComputedStyle = window.getComputedStyle(tr);
            return {
                width: tr.offsetWidth + "px",
                textAlign: trComputedStyle.textAlign,
                padding: trComputedStyle.padding,
                fontSize: trComputedStyle.fontSize,
                fontWeight: trComputedStyle.fontWeight,
                color: trComputedStyle.color,
                backgroundColor: trComputedStyle.backgroundColor,
                border: trComputedStyle.border,
                display: trComputedStyle.display,
                position: trComputedStyle.position,
                left: trComputedStyle.left,
                boxSizing: trComputedStyle.boxSizing,
                zIndex: trComputedStyle.zIndex,
                headerChildren: Array.from(tr.children).map((th) => { // Convert children to an array
                    const thComputedStyle = window.getComputedStyle(th);
                    return {
                        width: th.offsetWidth + "px",
                        textAlign: thComputedStyle.textAlign,
                        padding: thComputedStyle.padding,
                        fontSize: thComputedStyle.fontSize,
                        fontWeight: thComputedStyle.fontWeight,
                        color: thComputedStyle.color,
                        backgroundColor: thComputedStyle.backgroundColor,
                        border: thComputedStyle.border,
                        display: thComputedStyle.display,
                        position: thComputedStyle.position,
                        left: thComputedStyle.left,
                        boxSizing: thComputedStyle.boxSizing,
                        zIndex: thComputedStyle.zIndex,
                    };
                })
            };
        });

        // console.log("THEADS", trStyles);
        return trStyles;
    }
    return [];
};

export const handleSyncHorizontalScroll = (isStickyHeaderVisible, stickyTableRef, mainTableRef) => {
    if (stickyTableRef.current) {
        // Find the parent element
        const stickyHeaderParentDiv = stickyTableRef?.current?.parentElement;
        const mainTableParentDiv = mainTableRef?.current?.parentElement;

        if (stickyHeaderParentDiv && mainTableParentDiv) {
            stickyHeaderParentDiv.addEventListener("scroll", () => {
                mainTableParentDiv.scrollLeft = stickyHeaderParentDiv.scrollLeft;
            })
            mainTableParentDiv.addEventListener("scroll", () => {
                stickyHeaderParentDiv.scrollLeft = mainTableParentDiv.scrollLeft;
            })

            if (isStickyHeaderVisible) {
                stickyHeaderParentDiv.scrollLeft = mainTableParentDiv.scrollLeft;
            }

          stickyHeaderParentDiv.style.overflow = "hidden"; // Change overflow to hidden
        }
    }
}

// const handleCalculateStyleTop = (tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop, setDifference) => {
//     if (tableHeaderRef.current) {
//         // Get the top of the end component
//         const endTop = Math.ceil(tableHeaderRef.current.getBoundingClientRect().top);
//         console.log("endTop", endTop);
//         let startBottom;

//         if(navigationStickyComponent && navigationStickyComponent.current){
//             const navBottom = navigationStickyComponent.current.getBoundingClientRect().bottom;
//             startBottom = navBottom ? Math.ceil(navBottom) : Math.ceil(filterStickyComponent.current.getBoundingClientRect().bottom);
//         } else {
//             // Fall back to `filterStickyComponent` if `navigationStickyComponent` is not available
//             startBottom = Math.ceil(filterStickyComponent.current.getBoundingClientRect().bottom);
//         }
//         console.log("startBottom", startBottom);
        
//         // // if scroll viewpoint is on default view
//         // if(scroll.current == 0){
//         //     setDifference(0)
//         //     setStyleTop(0)
//         // //if scroll up
//         // }else if(scroll.current < scroll.previous) {
//             setDifference(startBottom - endTop)
//         // //if scroll down
//         // }else{
//         //     setDifference(Math.abs(endTop - startBottom))
//         // }
//     }
// }

export const handleCalculateTop = (filterStickyComponent, setStyleTop) => {
    const navbarElement = document.querySelector('.navbar'); // Select the element by class name
    let navbarHeight = 0;

    if (navbarElement) {
        navbarHeight = navbarElement.getBoundingClientRect().height; // Get the height of the element
        // console.log("Navbar height:", navbarHeight);
    }

    // console.log("this is scrolling", filterStickyComponent?.current?.getBoundingClientRect())
    setStyleTop(navbarHeight + filterStickyComponent?.current?.getBoundingClientRect().height);
}

const handleCalculateStyleTop = (tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop) => {
    if (tableHeaderRef.current) {
        // Get the top of the end component
        // const endTop = Math.ceil(tableHeaderRef.current.getBoundingClientRect().top);
        // console.log("endTop", endTop);

        let totalHeight = 15;

        // const navbarElement = document.querySelector('.navbar'); // Select the element by class name
        // let navbarHeight = 0;

        // if (navbarElement) {
        //     navbarHeight = navbarElement.getBoundingClientRect().height; // Get the height of the element
        //     console.log("Navbar height:", navbarHeight);
        //     totalHeight += navbarHeight/2;
        // }

        // Calculate height of navigationStickyComponent if it exists
        if (navigationStickyComponent && navigationStickyComponent.current) {
            const navHeight = navigationStickyComponent.current.getBoundingClientRect().height;
            // console.log("navigation sticky height: ", navHeight);
            totalHeight += navHeight;
        } else if (tableHeaderRef && tableHeaderRef.current) {
            const tableHeaderHeight = tableHeaderRef.current.getBoundingClientRect().height;
            // console.log("header height: ", tableHeaderHeight);
            // console.log("75% of header height: ", tableHeaderHeight/2);
            totalHeight += tableHeaderHeight/2;

        }

        // Add height of filterStickyComponent
        if (filterStickyComponent && filterStickyComponent.current) {
            const filterHeight = filterStickyComponent.current.getBoundingClientRect().height;
            console.log("filterHeight: ", filterHeight);
            totalHeight += filterHeight;
        }
        setStyleTop(totalHeight);
        console.log("totalHeight", totalHeight);

        // Calculate the difference between total height and the table header's top
        // setDifference(totalHeight - endTop);
    }
};


export const handleStickyHeaderObserver = (isStickyHeaderVisible, tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop, currentRootMargin, setIsStickyHeaderVisible, setHeaderStyles, stickyTableRef = null, mainTableRef = null) => {
    const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting && isStickyHeaderVisible) {
                setIsStickyHeaderVisible(false);
            } else if (!entry.isIntersecting && !isStickyHeaderVisible) {
                setHeaderStyles(syncHeaderStyles(tableHeaderRef));
                setIsStickyHeaderVisible(true);
            }
        },
        { 
            rootMargin: currentRootMargin,
            threshold: 0.001,
        }
    );

    // handleCalculateStyleTop(tableHeaderRef,navigationStickyComponent,filterStickyComponent, setStyleTop);
    
    if (tableHeaderRef.current) {
        observer.observe(tableHeaderRef.current);
    }

    // Handle syncing of the horizontal scroll
    if (stickyTableRef && mainTableRef) {
        handleSyncHorizontalScroll(isStickyHeaderVisible, stickyTableRef, mainTableRef);
    }

    return () => observer.disconnect();
}

export const stickyHeaderResizeListener = (setHeaderStyles, tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop) => {
    // Sync header styles on mount and window resize
    setHeaderStyles(syncHeaderStyles(tableHeaderRef));

    window.addEventListener("resize", () => {
        setHeaderStyles(syncHeaderStyles(tableHeaderRef))
        handleCalculateStyleTop(tableHeaderRef, navigationStickyComponent, filterStickyComponent, setStyleTop)
    });

    return () => {
        window.addEventListener("resize", () => {
            setHeaderStyles(syncHeaderStyles(tableHeaderRef))
        });
    };
}